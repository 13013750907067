import React, { useCallback, useEffect, useState } from 'react';

import Input from '../../Fields/components/Input';
import { setField } from '../../../DDPJS/DDPJS';

function TextField(enabledField, task) {
  const getValueFromTask = useCallback(() => {
    let value = task.fields[enabledField.id];
    if (enabledField.id === 'DatabaseID') value = task.$ID;

    if (typeof value === 'object') value = '';

    return value;
  }, [enabledField.id, task.$ID, task.fields]);

  let [isEditing, setIsEditing] = useState(false);
  let [value, setValue] = useState(getValueFromTask());

  useEffect(() => {
    let newValue = getValueFromTask();
    if (newValue !== value) setValue(newValue);
  }, [task.fields, getValueFromTask, value]);

  return (
    <td
      key={enabledField.id}
      className="field"
      onClick={() => {
        if (enabledField.readOnly) return;

        setIsEditing(true);
      }}
    >
      {isEditing ? (
        <Input
          value={value === null ? '' : value}
          onChange={(newValue) => {
            setValue(newValue);
            setField(enabledField.id, task.$ID, newValue);
            setIsEditing(false);
            return newValue;
          }}
          readOnly={enabledField.readOnly}
          selectOnFocus={true}
        />
      ) : (
        value
      )}
    </td>
  );
}

export default TextField;
