export function formatYYYYMMDD(date) {
  if (date && date instanceof Date) {
    return date.toLocaleDateString(navigator.language);
  } else {
    return '';
  }
}

export function formatTime24Hour(date) {
  if (date && date instanceof Date) {
    return date.toLocaleTimeString(navigator.language);
  } else {
    return '';
  }
}

export function getLocaleDateString() {
  const formats = {
    'AR-SA': 'DD/MM/YY',
    'BG-BG': 'DD.M.YYYY',
    'CA-ES': 'DD/MM/YYYY',
    'ZH-TW': 'YYYY/M/D',
    'CS-CZ': 'D.M.YYYY',
    'DA-DK': 'DD-MM-YYYY',
    'DE-DE': 'DD.MM.YYYY',
    'EL-GR': 'D/M/YYYY',
    'EN-US': 'M/D/YYYY',
    'FI-FI': 'D.M.YYYY',
    'FR-FR': 'DD/MM/YYYY',
    'HE-IL': 'DD/MM/YYYY',
    'HU-HU': 'YYYY. MM. DD.',
    'IS-IS': 'D.M.YYYY',
    'IT-IT': 'DD/MM/YYYY',
    'JA-JP': 'YYYY/MM/DD',
    'KO-KR': 'YYYY-MM-DD',
    'NL-NL': 'D-M-YYYY',
    'NB-NO': 'DD.MM.YYYY',
    'PL-PL': 'YYYY-MM-DD',
    'PT-BR': 'D/M/YYYY',
    'RO-RO': 'DD.MM.YYYY',
    'RU-RU': 'DD.MM.YYYY',
    'HR-HR': 'D.M.YYYY',
    'SK-SK': 'D. M. YYYY',
    'SQ-AL': 'YYYY-MM-DD',
    'SV-SE': 'YYYY-MM-DD',
    'TH-TH': 'D/M/YYYY',
    'TR-TR': 'DD.MM.YYYY',
    'UR-PK': 'DD/MM/YYYY',
    'ID-ID': 'DD/MM/YYYY',
    'UK-UA': 'DD.MM.YYYY',
    'BE-BY': 'DD.MM.YYYY',
    'SL-SI': 'D.M.YYYY',
    'ET-EE': 'D.MM.YYYY',
    'LV-LV': 'YYYY.MM.DD.',
    'LT-LT': 'YYYY.MM.DD',
    'FA-IR': 'MM/DD/YYYY',
    'VI-VN': 'DD/MM/YYYY',
    'HY-AM': 'DD.MM.YYYY',
    'AZ-LATN-AZ': 'DD.MM.YYYY',
    'EU-ES': 'YYYY/MM/DD',
    'MK-MK': 'DD.MM.YYYY',
    'AF-ZA': 'YYYY/MM/DD',
    'KA-GE': 'DD.MM.YYYY',
    'FO-FO': 'DD-MM-YYYY',
    'HI-IN': 'DD-MM-YYYY',
    'MS-MY': 'DD/MM/YYYY',
    'KK-KZ': 'DD.MM.YYYY',
    'KY-KG': 'DD.MM.YY',
    'SW-KE': 'M/D/YYYY',
    'UZ-LATN-UZ': 'DD/MM YYYY',
    'TT-RU': 'DD.MM.YYYY',
    'PA-IN': 'DD-MM-YY',
    'GU-IN': 'DD-MM-YY',
    'TA-IN': 'DD-MM-YYYY',
    'TE-IN': 'DD-MM-YY',
    'KN-IN': 'DD-MM-YY',
    'MR-IN': 'DD-MM-YYYY',
    'SA-IN': 'DD-MM-YYYY',
    'MN-MN': 'YY.MM.DD',
    'GL-ES': 'DD/MM/YY',
    'KOK-IN': 'DD-MM-YYYY',
    'SYR-SY': 'DD/MM/YYYY',
    'DV-MV': 'DD/MM/YY',
    'AR-IQ': 'DD/MM/YYYY',
    'ZH-CN': 'YYYY/M/D',
    'DE-CH': 'DD.MM.YYYY',
    'EN-GB': 'DD/MM/YYYY',
    'ES-MX': 'DD/MM/YYYY',
    'FR-BE': 'D/MM/YYYY',
    'IT-CH': 'DD.MM.YYYY',
    'NL-BE': 'D/MM/YYYY',
    'NN-NO': 'DD.MM.YYYY',
    'PT-PT': 'DD-MM-YYYY',
    'SR-LATN-CS': 'D.M.YYYY',
    'SV-FI': 'D.M.YYYY',
    'AZ-CYRL-AZ': 'DD.MM.YYYY',
    'MS-BN': 'DD/MM/YYYY',
    'UZ-CYRL-UZ': 'DD.MM.YYYY',
    'AR-EG': 'DD/MM/YYYY',
    'ZH-HK': 'D/M/YYYY',
    'DE-AT': 'DD.MM.YYYY',
    'EN-AU': 'D/MM/YYYY',
    'ES-ES': 'DD/MM/YYYY',
    'FR-CA': 'YYYY-MM-DD',
    'SR-CYRL-CS': 'D.M.YYYY',
    'AR-LY': 'DD/MM/YYYY',
    'ZH-SG': 'D/M/YYYY',
    'DE-LU': 'DD.MM.YYYY',
    'EN-CA': 'DD/MM/YYYY',
    'ES-GT': 'DD/MM/YYYY',
    'FR-CH': 'DD.MM.YYYY',
    'AR-DZ': 'DD-MM-YYYY',
    'ZH-MO': 'D/M/YYYY',
    'DE-LI': 'DD.MM.YYYY',
    'EN-NZ': 'D/MM/YYYY',
    'ES-CR': 'DD/MM/YYYY',
    'FR-LU': 'DD/MM/YYYY',
    'AR-MA': 'DD-MM-YYYY',
    'EN-IE': 'DD/MM/YYYY',
    'ES-PA': 'MM/DD/YYYY',
    'FR-MC': 'DD/MM/YYYY',
    'AR-TN': 'DD-MM-YYYY',
    'EN-ZA': 'YYYY/MM/DD',
    'ES-DO': 'DD/MM/YYYY',
    'AR-OM': 'DD/MM/YYYY',
    'EN-JM': 'DD/MM/YYYY',
    'ES-VE': 'DD/MM/YYYY',
    'AR-YE': 'DD/MM/YYYY',
    'EN-029': 'MM/DD/YYYY',
    'ES-CO': 'DD/MM/YYYY',
    'AR-SY': 'DD/MM/YYYY',
    'EN-BZ': 'DD/MM/YYYY',
    'ES-PE': 'DD/MM/YYYY',
    'AR-JO': 'DD/MM/YYYY',
    'EN-TT': 'DD/MM/YYYY',
    'ES-AR': 'DD/MM/YYYY',
    'AR-LB': 'DD/MM/YYYY',
    'EN-ZW': 'M/D/YYYY',
    'ES-EC': 'DD/MM/YYYY',
    'AR-KW': 'DD/MM/YYYY',
    'EN-PH': 'M/D/YYYY',
    'ES-CL': 'DD-MM-YYYY',
    'AR-AE': 'DD/MM/YYYY',
    'ES-UY': 'DD/MM/YYYY',
    'AR-BH': 'DD/MM/YYYY',
    'ES-PY': 'DD/MM/YYYY',
    'AR-QA': 'DD/MM/YYYY',
    'ES-BO': 'DD/MM/YYYY',
    'ES-SV': 'DD/MM/YYYY',
    'ES-HN': 'DD/MM/YYYY',
    'ES-NI': 'DD/MM/YYYY',
    'ES-RP': 'DD/MM/YYYY',
    'AM-ET': 'D/M/YYYY',
    'TZM-LATN-DZ': 'DD-MM-YYYY',
    'IU-LATN-CA': 'D/MM/YYYY',
    'SMA-NO': 'DD.MM.YYYY',
    'MN-MONG-CN': 'YYYY/M/D',
    'GD-GB': 'DD/MM/YYYY',
    'EN-MY': 'D/M/YYYY',
    'PRS-AF': 'DD/MM/YY',
    'BN-BD': 'DD-MM-YY',
    'WO-SW': 'DD/MM/YYYY',
    'RW-RW': 'M/D/YYYY',
    'QUT-GT': 'DD/MM/YYYY',
    'SAH-RU': 'MM.DD.YYYY',
    'GSW-FR': 'DD/MM/YYYY',
    'CO-FR': 'DD/MM/YYYY',
    'OC-FR': 'DD/MM/YYYY',
    'MI-NZ': 'DD/MM/YYYY',
    'GA-IE': 'DD/MM/YYYY',
    'SE-SE': 'YYYY-MM-DD',
    'BR-FR': 'DD/MM/YYYY',
    'SMN-FI': 'D.M.YYYY',
    'MOH-CA': 'M/D/YYYY',
    'ARN-CL': 'DD-MM-YYYY',
    'II-CN': 'YYYY/M/D',
    'DSB-DW': 'D. M. YYYY',
    'IG-NG': 'D/M/YYYY',
    'KL-GL': 'DD-MM-YYYY',
    'LB-LU': 'DD/MM/YYYY',
    'BA-RU': 'DD.MM.YY',
    'NSO-ZA': 'YYYY/MM/DD',
    'QUZ-BO': 'DD/MM/YYYY',
    'YO-NG': 'D/M/YYYY',
    'HA-LATN-NG': 'D/M/YYYY',
    'FIL-PH': 'M/D/YYYY',
    'PS-AF': 'DD/MM/YY',
    'FY-NL': 'D-M-YYYY',
    'NE-NP': 'M/D/YYYY',
    'SE-NO': 'DD.MM.YYYY',
    'IU-CANS-CA': 'D/M/YYYY',
    'SR-LATN-RS': 'D.M.YYYY',
    'SI-LK': 'YYYY-MM-DD',
    'SR-CYRL-RS': 'D.M.YYYY',
    'LO-LA': 'DD/MM/YYYY',
    'KM-KH': 'YYYY-MM-DD',
    'CY-GB': 'DD/MM/YYYY',
    'BO-CN': 'YYYY/M/D',
    'SMS-FI': 'D.M.YYYY',
    'AS-IN': 'DD-MM-YYYY',
    'ML-IN': 'DD-MM-YY',
    'EN-IN': 'DD-MM-YYYY',
    'OR-IN': 'DD-MM-YY',
    'BN-IN': 'DD-MM-YY',
    'TK-TM': 'DD.MM.YY',
    'BS-LATN-BA': 'D.M.YYYY',
    'MT-MT': 'DD/MM/YYYY',
    'SR-CYRL-ME': 'D.M.YYYY',
    'SE-FI': 'D.M.YYYY',
    'ZU-ZA': 'YYYY/MM/DD',
    'XH-ZA': 'YYYY/MM/DD',
    'TN-ZA': 'YYYY/MM/DD',
    'HSB-DE': 'D. M. YYYY',
    'BS-CYRL-BA': 'D.M.YYYY',
    'TG-CYRL-TJ': 'DD.MM.YY',
    'SR-LATN-BA': 'D.M.YYYY',
    'SMJ-NO': 'DD.MM.YYYY',
    'RM-CH': 'DD/MM/YYYY',
    'SMJ-SE': 'YYYY-MM-DD',
    'QUZ-EC': 'DD/MM/YYYY',
    'QUZ-PE': 'DD/MM/YYYY',
    'HR-BA': 'D.M.YYYY.',
    'SR-LATN-ME': 'D.M.YYYY',
    'SMA-SE': 'YYYY-MM-DD',
    'EN-SG': 'D/M/YYYY',
    'UG-CN': 'YYYY-M-D',
    'SR-CYRL-BA': 'D.M.YYYY',
    'ES-US': 'M/D/YYYY',
  };

  const language = navigator.language.toUpperCase();
  let format = formats[language];
  if (format) {
    return format;
  } else {
    for (let locale in formats) {
      if (locale.indexOf(language) === 0) {
        return formats[locale];
      }
    }
  }

  return 'DD/MM/YYYY';
}
