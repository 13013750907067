import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Form, Message, Grid } from 'semantic-ui-react';
import '../../semantic/dist/semantic.min.css';
import { loginHansoft } from '../../DDPJS/DDPJS';

import { userActions } from '../../actions/user.actions';
import styles from './Login.module.scss';
import { ImgLoginLogo } from '../../images/images';
import MessageDialog from '../MessageDialog/MessageDialog';
import { triggerMessageDialog } from '../MessageDialog/MessageUtilities';
import { history } from '../../helpers/history';
import { Copyright } from './Copyright';
import * as VC from '../../services/versioncontrol';

export class Login extends React.Component {
  constructor(props) {
    super(props);

    // reset login status
    this.props.dispatch(userActions.logout());

    this.state = {
      username: '',
      password: '',
      submitted: false,
      message: '',
      error: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();

    this.doLogin();
  }

  getBrowserName() {
    const navUserAgent = navigator.userAgent;
    let browserName = navigator.appName;

    let tempNameOffset, tempVersionOffset;

    if ((tempVersionOffset = navUserAgent.indexOf('Version')) !== -1) {
      browserName = 'Opera';
    } else if ((tempVersionOffset = navUserAgent.indexOf('MSIE')) !== -1) {
      browserName = 'Microsoft IE';
    } else if ((tempVersionOffset = navUserAgent.indexOf('Chrome')) !== -1) {
      browserName = 'Chrome';
    } else if ((tempVersionOffset = navUserAgent.indexOf('Safari')) !== -1) {
      browserName = 'Safari';
    } else if ((tempVersionOffset = navUserAgent.indexOf('Firefox')) !== -1) {
      browserName = 'Firefox';
    } else if (
      (tempNameOffset = navUserAgent.lastIndexOf(' ') + 1) <
      (tempVersionOffset = navUserAgent.lastIndexOf('/'))
    ) {
      browserName = navUserAgent.substring(tempNameOffset, tempVersionOffset);
    }

    return browserName;
  }

  doLogin() {
    let from = this.props.location.state
      ? this.props.location.state.from
      : null;

    let integrationData = null;
    if (!from) {
      const data = sessionStorage.getItem('integration');
      if (data) {
        integrationData = JSON.parse(data);
      }
    }

    this.setState({ submitted: true });
    const { username, password } = this.state;

    let source =
      integrationData && integrationData.data.source
        ? integrationData.data.source
        : this.getBrowserName();

    loginHansoft(username, password, source, () => {
      const search = (from && from.search) || '';
      if (from && from.pathname !== '/') {
        history.push(from.pathname + search);
      } else if (
        integrationData &&
        integrationData.data.redirectPath &&
        integrationData.data.redirectPath.length !== 0
      ) {
        history.push(integrationData.data.redirectPath);
      } else {
        history.push('/' + search);
      }
    });
  }

  handleError(e) {
    this.setState({ value: e.target.value });
  }

  handleKeyPress(e) {
    // "Enter" key
    if (e.keyCode === 13) {
      this.doLogin();
    }
  }

  componentDidUpdate() {
    if (this.props.errormessage.iserror)
      triggerMessageDialog(this.props.errormessage.iserror);
  }

  componentDidMount() {
    VC.getServiceInfo();
    document.addEventListener('keypress', this.handleKeyPress);
    triggerMessageDialog(this.props.errormessage.iserror);
  }

  componentWillUnmount() {
    document.removeEventListener('keypress', this.handleKeyPress);
  }

  render() {
    if (localStorage.getItem('user')) {
      return <Redirect to={'/'} />;
    }

    const { username, password } = this.state;
    const { loginError, serviceVersion } = this.props;

    let message;
    if (loginError.iserror) {
      message = (
        <Message
          id="js-login-message"
          negative
          className={`${styles.LoginMessageStyle}`}
        >
          <Message.Content>
            <span className={styles.Label}>Login Error:&nbsp;</span>
            {loginError.message}
          </Message.Content>
        </Message>
      );
    }

    const isLoginDisabled = username.length === 0 || password.length === 0;

    return (
      <div>
        <MessageDialog />

        <div className={styles.LoginPageStyle}>
          <Grid textAlign="center">
            <Grid.Row />
            <Grid.Row />
            <Grid.Row>
              <img src={ImgLoginLogo} alt={'logo'} />
            </Grid.Row>
            {message}
            <Grid.Row>
              <div className={styles.Center}>
                <Form
                  name="form"
                  onSubmit={this.handleSubmit}
                  error={this.state.error}
                >
                  <Form.Field className={styles.LoginForm}>
                    <input
                      id="js-login-username"
                      type="text"
                      name="username"
                      placeholder="Username"
                      value={username}
                      onChange={this.handleChange}
                    />
                  </Form.Field>
                  <Form.Field
                    className={`${styles.LoginForm} ${styles.PasswordField}`}
                  >
                    <input
                      id="js-login-password"
                      type="password"
                      name="password"
                      placeholder="Password"
                      value={password}
                      onChange={this.handleChange}
                    />
                  </Form.Field>
                  <Button
                    id="js-login"
                    primary
                    type="submit"
                    className={`${styles.LoginButton}`}
                    disabled={isLoginDisabled}
                  >
                    Log in
                  </Button>
                </Form>
              </div>
            </Grid.Row>
          </Grid>
          <Copyright serviceVersion={serviceVersion} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentuser: state.currentuser,
  loginError: state.loginError,
  errormessage: state.errormessage,
  serviceVersion: state.serviceVersion.serviceVersion,
});

export default connect(mapStateToProps)(Login);
