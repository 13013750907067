import { combineReducers } from 'redux';
import todos from './todos.reducer';
import currentuser from './user.reducer';
import filteroptions from './filter.reducer';
import ids from './DDPReducer';
import loginError from './loginError.reducer';
import appState from './state.reducer';
import multilinefields from './multiline.reducer';
import projects from './project.reducer';
import resources from './resource.reducer';
import singlelinefields from './singleline.reducer';
import collapseState from './collapse.reducer';
import comments from './comment.reducer';
import sprints from './sprint.reducer';
import groups from './group.reducer';
import workflows from './workflow.reducer';
import errormessage from './errormessage.reducer';
import milestones from './milestone.reducer';
import dashboards from './dashboards.reducer';
import chartResultSets from './chartresultsets.reducer';
import serviceVersion from './serviceVersion.reducer';

export default combineReducers({
  todos,
  currentuser,
  filteroptions,
  ids,
  loginError,
  appState,
  multilinefields,
  projects,
  resources,
  singlelinefields,
  collapseState,
  comments,
  sprints,
  groups,
  workflows,
  errormessage,
  milestones,
  dashboards,
  chartResultSets,
  serviceVersion,
});
