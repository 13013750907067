import { store } from '../helpers/store';
import {
  findWorkflow,
  getConnectedStatuses,
} from '../reducers/workflow.reducer';
import { filterFieldsByProject } from '../reducers/singleline.reducer';
import { getProjectInfo } from '../reducers/project.reducer';
import { isValidObject } from '../helpers/validation_functions';

export const workflowService = {
  markFieldAsRequiredForWorkflowState,
};

function markFieldAsRequiredForWorkflowState(
  fieldID,
  itemID,
  projectID,
  workflowID,
  bCheckForReadOnly,
) {
  const projectData = getProjectInfo(projectID);
  if (!projectData || projectData.length < 2 || !projectData[1]) return false;
  const workflow = findWorkflow(projectData[1].id, workflowID);
  if (!isValidObject(workflow)) return false;

  const state = store.getState();
  let currentItem = state.todos.find(function (item) {
    return item.$ID === itemID;
  });
  if (!isValidObject(currentItem)) {
    return false;
  }

  const workflowStatus = currentItem.getProperty('WorkflowStatus');
  if (!isValidObject(workflowStatus)) {
    return false;
  }
  const connectedStatuses = getConnectedStatuses(workflow, workflowStatus);
  if (!isValidObject(connectedStatuses)) {
    return false;
  }

  for (const item of connectedStatuses) {
    if ('RequiredFields' in item) {
      if (!Array.isArray(item.RequiredFields)) {
        continue;
      }
      for (const item1 of item.RequiredFields) {
        if (item1.FieldID === fieldID) {
          // being used to check for read only status
          if (bCheckForReadOnly) {
            return isItemFieldSet(fieldID, projectData[0].id, currentItem)
              ? false
              : 'ResourcesCanEditAllFieldsThatMustBeSetForThisTransition' in
                  item1 &&
                  item1.ResourcesCanEditAllFieldsThatMustBeSetForThisTransition;
            // being used to check for required field
          } else {
            return !isItemFieldSet(fieldID, projectData[0].id, currentItem);
          }
        }
      }
    }
  }

  return false;
}

function isItemFieldSet(fieldID, projectID, currentItem) {
  const fields = filterFieldsByProject(projectID);
  const field = fields.find(
    function (field) {
      return field.id === this.fieldname;
    },
    { fieldname: fieldID },
  );
  if (!isValidObject(field)) return true;

  const property = currentItem.getProperty(fieldID);

  switch (field.Type) {
    case 'Enum':
    case 'BugPriority':
    case 'SprintPriority':
      if (fieldID.startsWith('CC')) {
        if (property === 0 || property === null) {
          return false;
        }
        return true;
      } else {
        if (property === 1) {
          return false;
        }
        return true;
      }
    case 'MultiEnum':
    case 'InlineMultiEnum':
    case 'Resources':
    case 'MilestoneMultiEnum':
      if (property.length === 0) {
        return false;
      }
      return true;
    case 'String':
    case 'Hyperlink':
    case 'Integer':
    case 'Float':
    case 'Hours':
    case 'DateTime':
    case 'DateTimeTime':
      // TODO: find a solution for this rule
      // eslint-disable-next-line no-case-declarations
      let myProperty = property;
      if (typeof property === 'string') {
        myProperty = myProperty.trim();
      }
      if (myProperty === null || myProperty === '') {
        return false;
      }
      return true;
    default:
      return true;
  }
}
