import React from 'react';
import PropTypes from 'prop-types';

export default class Input extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
  }

  state = {
    value: this.props.value,
  };

  componentDidMount() {
    if (this.props.selectOnFocus) {
      this.inputRef.select();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.value !== this.props.value &&
      this.props.number &&
      !(isNaN(prevProps.value) && isNaN(this.props.value))
    ) {
      this.setState({
        value: this.props.value,
      });
    }
  }

  onChange(event) {
    this.setState({
      value: event.target.value,
    });
  }

  onKeyPress(event) {
    if (event.key === 'Enter') this.inputRef.blur();
  }

  onKeyDown(event) {
    if (event.key === 'Escape') this.inputRef.blur();
  }

  onBlur() {
    const isEmpty =
      typeof this.state.value === 'string' && !this.state.value.trim();
    const parsedValue = this.props.number
      ? this.props.decimal
        ? parseFloat(this.state.value)
        : parseInt(this.state.value)
      : this.state.value;

    this.setState({
      value: this.props.onChange(isEmpty ? null : parsedValue),
    });
  }

  render() {
    return (
      <input
        type="text"
        className="input js-input"
        ref={(element) => (this.inputRef = element)}
        value={this.state.value}
        readOnly={this.props.readOnly}
        onChange={this.props.readOnly ? null : this.onChange}
        onBlur={this.props.readOnly ? null : this.onBlur}
        onKeyPress={this.props.readOnly ? null : this.onKeyPress}
        onKeyDown={this.props.readOnly ? null : this.onKeyDown}
      />
    );
  }
}

Input.propTypes = {
  decimal: PropTypes.bool,
  number: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  selectOnFocus: PropTypes.bool,
};
