// Shared data for all integrations
let integrationData = {
  // make bind Hansoft item with other party object
  onBind: null,
  // make unbind Hansoft item from other party object
  onUnbind: null,
  // properties of other party objects( we allow to bind to multiply object from other side - so this will be an array by default)
  otherParties: [],
  // current hansoft ids
  hansoftIds: [],
  // column name to visualize
  columnName: '',
  // redirect integrationpath
  redirectPath: '',
  // intergration source which we will specify for token generation
  source: '',
  // header to show
  header: '',
  // this one is for reset integration data afterwards
  cleanupIntegrationData: false,
};

export default integrationData;
