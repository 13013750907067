import React, { Component } from 'react';
import { Router, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import '../semantic/dist/semantic.min.css';
import moment from 'moment';
import './App.scss';

import { history } from '../helpers/history';
import { alertActions } from '../actions/alert.constants';
import { flagReconnect, reconnectHansoft } from '../DDPJS/DDPJS';
import { doesRefreshTokenExist } from '../tokens/refresh.token';

import '../palette.scss';
import DashboardsRoute from '../components/dashboards/DashboardsRoute';
import DashboardsContainer from '../components/dashboards/containers/Dashboards';

// Integrations
import HelixCoreRoute from '../web_integrations/helix_core/helixcoreroute.js';
import Layout from '../components/ToDoList/Layout/Layout';
import Login from '../components/Login';
import { DetailsRoute } from '../components/Routes/DetailsRoute';
import IDLayout from '../components/ItemDetail/IDLayout';
import { PrivateRoute } from '../components/Routes/PrivateRoute';
import { Routes } from '../enums';

export function getThemeName() {
  return localStorage.getItem('theme') || 'dark';
}

function getIntegration() {
  const data = sessionStorage.getItem('integration');
  if (data) {
    return JSON.parse(data);
  } else {
    return {
      enabled: false,
      data: {},
    };
  }
}

export class App extends Component {
  constructor(props) {
    super(props);

    if (doesRefreshTokenExist()) {
      console.log('Reconnecting to Hansoft');
      reconnectHansoft();
    }

    const { dispatch } = this.props;
    history.listen(() => {
      // clear alert on location change
      dispatch(alertActions.clear());
    });

    try {
      require('moment/locale/' + navigator.language);
      moment.locale(navigator.language);
    } catch (error) {
      try {
        if (navigator.language.indexOf('-') !== -1) {
          require('moment/locale/' + navigator.language.split('-')[0]);
          moment.locale(navigator.language.split('-')[0]);
        }
      } catch (innerError) {
        // TODO: Render error page if this error is thrown
      }
    }

    this.handleRefresh = this.handleRefresh.bind(this);
    this.onToggleTheme = this.onToggleTheme.bind(this);
    this.onToggleCoverImages = this.onToggleCoverImages.bind(this);
    this.onToogleIntegration = this.onToogleIntegration.bind(this);

    this.state = {
      flagReconnect: flagReconnect,
      theme: getThemeName(),
      showCoverImages: this.getShowCoverImages(),
      integration: getIntegration(),
    };
  }

  getShowCoverImages() {
    const showCoverImages = localStorage.getItem('showCoverImages');
    if (showCoverImages === undefined || showCoverImages === null) return false;
    return showCoverImages === 'true';
  }

  onToggleTheme() {
    localStorage.setItem('theme', getThemeName() === 'dark' ? 'light' : 'dark');
    this.setState({
      theme: getThemeName(),
    });
  }

  onToggleCoverImages() {
    localStorage.setItem('showCoverImages', !this.getShowCoverImages());
    this.setState({
      showCoverImages: this.getShowCoverImages(),
    });
  }

  onToogleIntegration(integrationData) {
    let newData = null;
    if (integrationData !== undefined && integrationData !== null) {
      newData = {
        enabled: true,
        data: integrationData,
      };
    } else {
      newData = {
        enabled: false,
        data: {},
      };
    }
    sessionStorage.setItem('integration', JSON.stringify(newData));
    this.setState({
      integration: newData,
    });
  }

  handleRefresh() {
    console.log('Browser refresh');
    this.state.reconnectHansoft();

    return '';
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.handleRefresh);
  }

  render() {
    let shared = {
      onToggleTheme: this.onToggleTheme,
      theme: this.state.theme,

      onToggleCoverImages: this.onToggleCoverImages,
      showCoverImages: this.state.showCoverImages,

      onToogleIntegration: this.onToogleIntegration,
      integration: this.state.integration,
    };

    return (
      <div className={`app theme--${getThemeName()}`}>
        <Router history={history}>
          <div style={{ height: '100%' }}>
            <PrivateRoute
              exact
              path={Routes.TODO_LIST}
              component={Layout}
              shared={shared}
            />
            <Route path={Routes.LOGIN} component={Login} />
            <DetailsRoute
              path={Routes.TASK}
              component={IDLayout}
              shared={shared}
            />
            <DashboardsRoute
              exact
              path={Routes.DASHBOARDS}
              component={DashboardsContainer}
              shared={shared}
            />
            <DashboardsRoute
              path={Routes.DASHBOARD}
              component={DashboardsContainer}
              shared={shared}
            />
            <HelixCoreRoute
              path={Routes.HELIX_CORE_INTEGRATION}
              component={Layout}
              shared={shared}
            />
          </div>
        </Router>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentuser: state.currentuser,
});

export default connect(mapStateToProps)(App);
