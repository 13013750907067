import { loginErrorConstants } from '../constants/loginError.constants';
import { CLEAR_CACHE } from '../constants/general.constants';

const loginError = (
  state = { iserror: false, authresult: 0, message: '' },
  action,
) => {
  switch (action.type) {
    case loginErrorConstants.SET_ERROR:
      return {
        ...state,
        iserror: true,
        authresult: action.result.authresult,
        message: action.error.error,
      };
    case loginErrorConstants.CLEAR_ERROR:
    case CLEAR_CACHE:
      return { ...state, iserror: false, authresult: 0, message: '' };
    default:
      return state;
  }
};

export default loginError;
