import { postComment } from '../../../DDPJS/DDPJS';
import { MsgLevelEnum } from '../../../components/MessageDialog/MessageIndex';
import { setIntegrationMessage } from '../../../components/MessageDialog/MessageUtilities';

const columnName = 'Helix Core';
const sourceName = 'Helix Core Integration';
const redirectPath = '/web_integrations/helix_core';
const HansoftTag = '#Hansoft: ';
const getIntegrationHeader = (changelist) =>
  `Add CL${changelist} to Hansoft tasks`;

let swarmEndPoint = '';

function updateLocalStorage(data) {
  let newData = {
    enabled: true,
    data: data,
  };
  sessionStorage.setItem('integration', JSON.stringify(newData));
}

function getFromLocalStorage(integrationData) {
  let savedData = null;

  const data = sessionStorage.getItem('integration');
  if (!data) {
    return false;
  }
  savedData = JSON.parse(data);
  if (!savedData.enabled || savedData.data.columnName !== columnName) {
    return false;
  }

  integrationData.otherParties = savedData.data.otherParties;
  integrationData.hansoftIds = savedData.data.hansoftIds;
  integrationData.columnName = savedData.data.columnName;
  integrationData.redirectPath = savedData.data.redirectPath;
  integrationData.source = savedData.data.source;
  integrationData.header = savedData.data.header;

  return true;
}

function generateP4VComment(hansoftTask, databaseGUIDHash) {
  let configContainer = window.localStorage.getItem('config');
  let config = JSON.parse(configContainer);
  let helixCoreCommentType =
    config && config.helixCoreCommentType
      ? config.helixCoreCommentType.toLowerCase()
      : 'none';

  if (helixCoreCommentType === 'task description') {
    return hansoftTask.fields.Description;
  } else if (helixCoreCommentType === 'database id') {
    return hansoftTask.$ID;
  } else if (helixCoreCommentType === 'url') {
    return (
      window.location.protocol +
      '//' +
      window.location.host +
      '/task/' +
      databaseGUIDHash +
      '/' +
      hansoftTask.$ID
    );
  }

  return '';
}

function getUpdatedComment(p4vCurrentComment, newTaskInfo, append) {
  p4vCurrentComment = p4vCurrentComment.trim();
  let iDataStart = p4vCurrentComment.indexOf(HansoftTag);

  // No hansoft data at all
  if (iDataStart === -1) {
    if (append) {
      return {
        newComment: p4vCurrentComment + ' ' + HansoftTag + newTaskInfo + ' ',
        originalComment: p4vCurrentComment,
      };
    } else {
      return {
        newComment: p4vCurrentComment,
        originalComment: p4vCurrentComment,
      };
    }
  }

  let iEnd = p4vCurrentComment.indexOf('#', iDataStart + HansoftTag.length);
  let p4vDescArray = p4vCurrentComment.split('');
  let prevHansoftData = '';
  if (iEnd === -1) {
    iEnd = p4vDescArray.length;
  }

  // There is a tag, but no Hansoft data
  prevHansoftData = p4vDescArray.splice(iDataStart, iEnd - iDataStart).join('');
  p4vCurrentComment = p4vDescArray.join('');
  p4vCurrentComment.trim();

  if (prevHansoftData.length === 0) {
    if (append) {
      return {
        newComment: p4vCurrentComment + HansoftTag + newTaskInfo + ' ',
        originalComment: p4vCurrentComment,
      };
    } else {
      return {
        newComment: p4vCurrentComment,
        originalComment: p4vCurrentComment,
      };
    }
  }

  // Check if hansoft data contains info about current task was already there
  iDataStart = prevHansoftData.indexOf(newTaskInfo);
  if (iDataStart === -1) {
    if (append) {
      p4vDescArray = prevHansoftData.split('');
      p4vDescArray.splice(0, HansoftTag.length);
      prevHansoftData = p4vDescArray.join('');

      return {
        newComment:
          p4vCurrentComment +
          HansoftTag +
          newTaskInfo +
          ' ; ' +
          prevHansoftData +
          ' ',
        originalComment: p4vCurrentComment,
      };
    } else {
      return {
        newComment: p4vCurrentComment + prevHansoftData + ' ',
        originalComment: p4vCurrentComment,
      };
    }
  } else {
    if (append) {
      return {
        newComment: p4vCurrentComment + prevHansoftData + ' ',
        originalComment: p4vCurrentComment,
      };
    } else {
      p4vDescArray = prevHansoftData.split('');
      let cutLength = newTaskInfo.length;
      if (p4vDescArray[iDataStart + cutLength + 1] === ';') {
        cutLength += 3;
      }

      p4vDescArray.splice(iDataStart, cutLength);
      prevHansoftData = p4vDescArray.join('');
      if (prevHansoftData === HansoftTag) {
        prevHansoftData = '';
      }
      prevHansoftData = prevHansoftData.trim();
      return {
        newComment: p4vCurrentComment + prevHansoftData + ' ',
        originalComment: p4vCurrentComment,
      };
    }
  }
}

function getHansoftComment(data, desc, isAdd) {
  let comment;
  if (swarmEndPoint.length !== 0) {
    comment = `Change <URL=${swarmEndPoint}${data.Change}>CL${data.Change}</URL>`;
  } else {
    comment = `Change CL${data.Change}`;
  }

  comment += isAdd ? ' added to ' : ' removed from ';

  comment +=
    `this task using client <BOLD>${data.Client}</BOLD>` +
    ` with the description: <BOLD>${desc}</BOLD>\n`;

  return comment;
}

function createP4VForm(data) {
  let p4vForm = {};

  p4vForm['Change'] = data.Change;
  p4vForm['Client'] = data.Client;
  p4vForm['User'] = data.User;
  p4vForm['Status'] = data.Status;
  p4vForm['Description'] = data.Description;

  let clFiles = '';
  Object.keys(data)
    .filter(function (k) {
      return k.indexOf('Files') == 0;
    })
    .every(function (file) {
      clFiles = clFiles + '\n\t' + data[file];
      return true;
    }, {});
  p4vForm['Files'] = clFiles;

  return p4vForm;
}

function initData(integrationData, p4vjs) {
  if (p4vjs === undefined) {
    return false;
  }

  let changeId;
  if (!getFromLocalStorage(integrationData)) {
    changeId = p4vjs.GetURLParameter('change');
    if (changeId === undefined || changeId === null) {
      const changeIds = p4vjs.GetURLParameter('changes');

      if (changeIds === undefined || changeIds.length === 0) {
        return false;
      }

      const ids = changeIds.split(',');

      // there is possible only one CL for binding right now
      if (ids.length !== 1) {
        setIntegrationMessage(
          MsgLevelEnum.WARNING,
          'You can select only one changelist',
        );
        return false;
      }

      if (isNaN(ids[0])) {
        setIntegrationMessage(
          MsgLevelEnum.WARNING,
          'You cannot add to the default changelist',
        );
        return false;
      }
      changeId = ids[0];
    }

    const req = 'change -o ' + changeId;
    p4vjs.p4(req).then(async (otherParty) => {
      if (
        otherParty.data[0].Status === 'submitted' &&
        otherParty.data[0].User !== (await p4vjs.getUser())
      ) {
        setIntegrationMessage(
          MsgLevelEnum.WARNING,
          'You cannot change the description of other user submitted changelist',
        );
        integrationData.cleanupIntegrationData = true;
      } else {
        integrationData.otherParties.push(otherParty);
        integrationData.header = getIntegrationHeader(
          integrationData.otherParties[0].data[0].Change,
        );
        updateLocalStorage(integrationData);
      }
    });

    integrationData.columnName = columnName;
    integrationData.redirectPath = redirectPath;
    integrationData.source = sourceName;
  }

  if (swarmEndPoint.length === 0) {
    p4vjs.p4('property -l -n P4.Swarm.URL').then(async (swarmInfo) => {
      if (swarmInfo !== undefined && swarmInfo.size > 0) {
        swarmEndPoint = swarmInfo.data[0].value + '/changes/';
      }
    });
  }

  integrationData.onBind = function (hansoftTask, databaseGUIDHash) {
    if (integrationData.otherParties.length === 0) {
      return;
    }

    integrationData.hansoftIds.push(hansoftTask.$ID);

    let descAppendix = generateP4VComment(hansoftTask, databaseGUIDHash);

    let commentToHansoft;
    for (let i in integrationData.otherParties) {
      const data = integrationData.otherParties[i].data[0];

      const res = getUpdatedComment(data.Description, descAppendix, true);
      data.Description = res.newComment.trim();

      commentToHansoft = getHansoftComment(data, res.originalComment, true);

      if (data.Status !== 'submitted') {
        p4vjs.p4('change -i ', createP4VForm(data)).then(() => {
          p4vjs.refresh(p4vjs.ObjectType.PENDINGCHANGE, changeId);
        });
      }
    }

    postComment(hansoftTask.$ID, '-1', commentToHansoft);
    updateLocalStorage(integrationData);
  };

  integrationData.onUnbind = function (hansoftTask, databaseGUIDHash) {
    if (integrationData.otherParties.length === 0) {
      return;
    }

    const ind = integrationData.hansoftIds.indexOf(hansoftTask.$ID);
    if (ind === -1) {
      return;
    } else {
      integrationData.hansoftIds.splice(ind, 1);
    }

    const descAppendix = generateP4VComment(hansoftTask, databaseGUIDHash);

    let commentToHansoft;
    for (let i in integrationData.otherParties) {
      const data = integrationData.otherParties[i].data[0];
      const res = getUpdatedComment(data.Description, descAppendix, false);
      data.Description = res.newComment.trim();

      commentToHansoft = getHansoftComment(data, res.originalComment, false);

      if (data.Status !== 'submitted') {
        p4vjs.p4('change -i ', createP4VForm(data)).then(() => {
          p4vjs.refresh(p4vjs.ObjectType.PENDINGCHANGE, changeId);
        });
      }
    }

    postComment(hansoftTask.$ID, '-1', commentToHansoft);
    updateLocalStorage(integrationData);
  };
  updateLocalStorage(integrationData);
  return true;
}

export { initData };
