import assignIn from 'lodash/merge';

import { singlelineConstants } from '../constants/singleline.constants';
import { projectConstants } from '../constants/project.constants';
import { extractFromString } from '../helpers/projectid_parser';
import { CLEAR_CACHE, REMOVE_FIELD } from '../constants/general.constants';
import { store } from '../helpers/store';
import { isValidObject } from '../helpers/validation_functions';
import { processUpdateColumnMetaData } from './fields.reducer';
import { getProjectInfo } from './project.reducer';

function createSingleline(action) {
  const projectID = extractFromString('ProjectMeta_', action.collection);
  let newObject = {
    id: action.id,
    projectID: projectID,
  };
  assignIn(newObject, action.fields);
  return newObject;
}

const singlelinefields = (state = [], action) => {
  let projectID;
  if ('collection' in action)
    projectID = extractFromString('ProjectMeta_', action.collection);
  switch (action.type) {
    case singlelineConstants.ADD_SINGLELINE:
      // TODO: find a solution for this rule
      // eslint-disable-next-line no-case-declarations
      const existingIndex = state.findIndex(
        (field) => field.id === action.id && field.projectID === projectID,
      );
      if (existingIndex >= 0) {
        let newState = [
          ...state.slice(0, existingIndex),
          ...state.slice(existingIndex + 1),
        ];

        return [...newState, createSingleline(action)];
      } else {
        return [...state, createSingleline(action)];
      }
    case projectConstants.UPDATE_PROJECTMETA:
      return processUpdateColumnMetaData(
        state,
        action,
        projectID,
        createSingleline,
      );
    case REMOVE_FIELD:
      // TODO: find a solution for this rule
      // eslint-disable-next-line no-case-declarations
      const myindex = state.findIndex(
        (field) => field.id === action.id && field.projectID === projectID,
      );
      if (myindex >= 0) {
        return [...state.slice(0, myindex), ...state.slice(myindex + 1)];
      } else {
        return state;
      }
    case projectConstants.REMOVE_PROJECT:
      return state.filter((field) => field.projectID !== projectID);
    case singlelineConstants.RESET:
    case CLEAR_CACHE:
      state.length = 0;
      return state;
    default:
      return state;
  }
};

export default singlelinefields;

export function filterCoreFieldsByProject(projectID) {
  const functor = function (field) {
    return (field.projectID === this.projectID) & !field.id.startsWith('CC');
  };
  return filterFields(projectID, functor);
}

export function filterFieldsByProject(projectID) {
  const functor = function (field) {
    return field.projectID === this.projectID;
  };
  return filterFields(projectID, functor);
}

// this was probably overkill, but I had fun doing it
function filterFields(projectID, functor) {
  const state = store.getState();
  return state.singlelinefields.filter(functor, {
    projectID: projectID,
  });
}

export function fieldIsEnabled(fieldID, task) {
  const projectInfo = getProjectInfo(task.$ProjectID);
  const property = task.getProperty('LinkedToSprint');
  if (projectInfo[0]) {
    if (projectInfo[0].Type === 'Planning') {
      if (property === null) {
        return [false, ''];
      } else {
        return [
          doesFieldExistInProject(projectInfo[0].id, fieldID),
          projectInfo[0].id,
        ];
      }
    } else if (projectInfo[0].Type === 'QA') {
      if (isValidObject(projectInfo[1])) {
        return [
          doesFieldExistInProject(
            property ? projectInfo[1].id : projectInfo[0].id,
            fieldID,
          ),
          property ? projectInfo[1].id : projectInfo[0].id,
        ];
      } else {
        return [false, ''];
      }
    } else if (projectInfo[0].Type === 'Backlog') {
      if (!isValidObject(property) || !isValidObject(projectInfo[1])) {
        return [false, ''];
      } else {
        return [
          doesFieldExistInProject(projectInfo[1].id, fieldID),
          projectInfo[1].id,
        ];
      }
    }
  } else {
    return [false, ''];
  }
}

export function doesFieldExistInProject(projectID, fieldID) {
  const state = store.getState();
  const foundField = state.singlelinefields.find(function (field) {
    return field.projectID === projectID && field.id === fieldID;
  });
  return isValidObject(foundField);
}

export function findFieldByProject(fieldID, projectID, subProjectID) {
  let myProjectID = 0;
  if (typeof projectID === 'string') {
    myProjectID = parseInt(projectID, 10);
  } else if (typeof projectID === 'number') {
    myProjectID = projectID;
  }

  const state = store.getState();
  // check for the field definition using project id first
  let field = state.singlelinefields.find((singleLineField) => {
    return (
      singleLineField.projectID === myProjectID &&
      singleLineField.id === fieldID
    );
  });
  // if not found, use the sub project id
  if (typeof field === 'undefined') {
    field = state.singlelinefields.find((singleLineField) => {
      return (
        singleLineField.projectID === subProjectID &&
        singleLineField.id === fieldID
      );
    });
  }
  return field;
}
