export function getUniqueFieldForType(type) {
  if (type === 'QA') {
    return ['DetailedDescription', 'Description'];
  } else if (type === 'Backlog') {
    return null;
  } else if (type === 'Planning') {
    return null;
  } else {
    return null;
  }
}
