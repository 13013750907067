import React from 'react';
import moment from 'moment';

import { getEJSONDateValue } from '../../EJSON/EJSON_Date';
import { formatYYYYMMDD } from '../../helpers/date_time_format';
import { getProjectInfo } from '../../reducers/project.reducer';
import { ImgSprint } from '../../images/images';
import Relation from './components/relation';
import { isValidObject } from '../../helpers/validation_functions';

export default class SprintControl extends React.Component {
  constructor(props) {
    super(props);
    this.createSprintString = this.createSprintString.bind(this);
  }

  createSprintString(value, projectID) {
    if (!isValidObject(value)) return '<unknown>';

    const mySprint = this.props.sprints.find(
      (sprint) =>
        sprint.id === value.toString() &&
        sprint.projectID === parseInt(projectID, 10),
    );
    if (isValidObject(mySprint)) {
      const startDateValue = new Date(getEJSONDateValue(mySprint, 'Start'));
      const startMoment = moment(startDateValue);
      const isStartValid = startMoment.isValid();
      const endDateValue = new Date(getEJSONDateValue(mySprint, 'End'));
      const endMoment = moment(endDateValue);
      const isEndValid = endMoment.isValid();

      let sprintString = mySprint.Name;
      sprintString += ' (';
      sprintString += isStartValid
        ? formatYYYYMMDD(startDateValue)
        : '<unknown>';
      sprintString += ' - ';
      sprintString += isEndValid ? formatYYYYMMDD(endDateValue) : '<unknown>';
      sprintString += ')';

      return sprintString;
    } else {
      return '<unknown>';
    }
  }

  render() {
    if (!('task' in this.props)) return null;

    const { LinkedToSprint } = this.props.task.fields;
    const { $ProjectID } = this.props.task;
    const projectData = getProjectInfo($ProjectID);

    if (!isValidObject(LinkedToSprint) || !projectData[1]) return null;

    return (
      <Relation
        icon={ImgSprint}
        text={this.createSprintString(LinkedToSprint, projectData[1].id)}
      />
    );
  }
}
