const noReleaseMilestone = {
  fields: {
    $ID: -128,
    Name: 'No release',
    Date: {
      $date: null,
    },
  },
};

export function getMilestoneFromID(milestoneID, milestones) {
  if (milestoneID === -128) return noReleaseMilestone;

  for (const item of milestones) {
    if (parseInt(item.$ID) === milestoneID) return item;
  }
}

export function getSortedMilestoneList(milestoneList) {
  milestoneList.sort(function (milestoneA, milestoneB) {
    if (milestoneA.fields.Date.$date < milestoneB.fields.Date.$date) return -1;
    else if (milestoneA.fields.Date.$date > milestoneB.fields.Date.$date)
      return 1;
    else if (milestoneA.fields.Name < milestoneB.fields.Name) return -1;
    else if (milestoneA.fields.Name > milestoneB.fields.Name) return 1;
    return 0;
  });

  return milestoneList;
}
