import React, { FC, useCallback, useEffect, useState } from 'react';
import { Task } from '../../../interfaces';
import { formatWork } from '../../../helpers/hours_field_functions';
import Input from '../components/Input';
import { setField } from '../../../DDPJS/DDPJS';
import sharedStyles from '../../../shared.module.scss';
import styles from './TimeSpentListField.module.scss';
import { checkIfFieldIsReadOnly, getFieldDefinition } from '../helpers';

interface IProps {
  fieldID: string;
  task: Task;
}

export const TimeSpentListField: FC<IProps> = ({ fieldID, task }: IProps) => {
  const [timeSpent, setTimeSpent] = useState<any>(task.getProperty(fieldID));
  const [inEditMode, setInEditMode] = useState<boolean>(false);

  useEffect(() => {
    if (timeSpent !== task.getProperty(fieldID)) {
      setTimeSpent(task.getProperty(fieldID));
    }
  }, [task.getProperty(fieldID)]);

  const disableEditMode = useCallback(() => {
    if (inEditMode) {
      setInEditMode(false);
    }
  }, [inEditMode]);

  const enableEditMode = useCallback(() => {
    if (!inEditMode && !checkIfFieldIsReadOnly(fieldID, task)) {
      setInEditMode(true);
    }
  }, [inEditMode]);

  const onTimeAdded = useCallback(
    (timeToBeAdded: number) => {
      if (!isNaN(timeToBeAdded)) {
        let newTimeSpent = timeSpent || 0;
        newTimeSpent += timeToBeAdded;

        if (newTimeSpent < 0) newTimeSpent = 0;

        setTimeSpent(newTimeSpent);

        if (newTimeSpent !== timeSpent)
          setField(
            getFieldDefinition(fieldID, task).id,
            task.$ID,
            newTimeSpent,
          );

        disableEditMode();
      }
    },
    [timeSpent, disableEditMode],
  );

  return (
    <>
      {getFieldDefinition(fieldID, task) && (
        <>
          {!inEditMode ? (
            <div
              id="time-spent-read-only"
              className={`${styles.timeSpentReadOnly} ${sharedStyles.displayFlex}`}
              onClick={enableEditMode}
            >
              {formatWork(timeSpent) || '(Not set)'}
            </div>
          ) : (
            <div className={sharedStyles.displayFlex}>
              <span className={styles.addTimeText}>Add time: </span>
              <Input
                id="time-spent-input"
                value=""
                onChange={onTimeAdded}
                decimal
                number
                selectOnFocus
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default TimeSpentListField;
