import React from 'react';

import BaseField from './BaseField';
import { setField } from '../../DDPJS/DDPJS';
import ItemDetailsField from './components/ItemDetailsField';
import { isValidObject } from '../../helpers/validation_functions';

export default class ClickEditBaseField extends BaseField {
  constructor(props) {
    super(props);
    this.onBlur = this.onBlur.bind(this);
    this.onChange = this.onChange.bind(this);
    this.toggleEditMode = this.toggleEditMode.bind(this);
  }

  state = {
    originalValue: this.getValue(),
    value: this.getValue(),
    editInputMode: false,
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.originalValue !== this.getValue()) {
      this.setState({ originalValue: this.getValue(), value: this.getValue() });
    }
    if (!prevState.editInputMode && this.state.editInputMode) {
      const inputField = this.refs.HyperlinkInputField;
      if (isValidObject(inputField)) {
        inputField.select();
      }
    }
  }

  onChange = (event) => {
    if (this.readOnlyFlag()) return;

    this.setState({ value: event.target.value });
  };

  onBlur = () => {
    if (this.readOnlyFlag()) {
      return;
    }

    this.setState({ editInputMode: false });

    if (this.state.value === this.state.originalValue) {
      return;
    }

    this.setState({ originalValue: this.state.value });

    setField(this.getFieldDefinition().id, this.getItemID(), this.state.value);
  };

  toggleEditMode() {
    this.setState({ editInputMode: !this.state.editInputMode });
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.onBlur(event);
    }
  };

  getViewMode() {
    return null;
  }

  render() {
    if (!('task' in this.props) || !isValidObject(this.props.task)) {
      return null;
    }

    if (!isValidObject(this.getFieldDefinition())) {
      return null;
    }

    const editInputMode = this.state.editInputMode;

    return (
      <ItemDetailsField
        fieldName={
          this.getFieldDefinition().id !== 'Description'
            ? this.getFieldDefinition().DisplayName
            : ''
        }
        isRequiredField={this.isWorkflowRequiredField()}
      >
        {editInputMode ? (
          <input
            autoFocus
            type="text"
            className="js-clickedit-input"
            // TODO: Fix this refs declaration
            // eslint-disable-next-line react/no-string-refs
            ref="HyperlinkInputField"
            onChange={(event) => {
              this.onChange(event);
            }}
            onBlur={(event) => {
              this.onBlur(event);
            }}
            onKeyPress={this.handleKeyPress}
            onFocus={(event) => event.target.select()}
            value={this.state.value}
            readOnly={this.readOnlyFlag()}
          />
        ) : (
          this.getViewMode()
        )}
      </ItemDetailsField>
    );
  }
}
