import React from 'react';
import { connect } from 'react-redux';

import { getProjectInfo } from '../../reducers/project.reducer';
import { getUniqueFieldForType } from '../../helpers/itemdetail_functions';
import MultilineEditor from './MultiLine/MultilineEditor';

class UniqueFieldControl extends React.Component {
  render() {
    const { multilinefields } = this.props;

    const currentItem = this.props.task;
    const projectData = getProjectInfo(currentItem.$ProjectID);
    const projectType = projectData[0].Type;
    const uniqueField = getUniqueFieldForType(projectType);
    if (uniqueField === null) return null;

    let fieldData = null;
    const myProjectID = parseInt(currentItem.$ProjectID, 10);
    const myMultilineFields = multilinefields.filter(
      (field) => field.projectID === myProjectID,
    );
    for (const item of myMultilineFields) {
      if (item.id === uniqueField[0]) {
        let fieldValue = '';
        if (item.id in currentItem.fields) {
          fieldValue = currentItem.fields[item.id];
        }
        fieldData = {
          id: currentItem.$ID,
          fieldID: item.id,
          fieldName: item.DisplayName,
          readOnly: item.ReadOnly,
          fieldValue: fieldValue,
        };
      }
    }

    if (fieldData === null) return null;

    return (
      <div style={UniqueControlStyle} id="uniquefield_link">
        <span>{fieldData.fieldName}</span>
        <div style={{ marginTop: '4px' }}>
          <MultilineEditor {...fieldData} />
        </div>
      </div>
    );
  }
}

const UniqueControlStyle = {
  padding: '0 0 16px',
};

const mapStateToProps = (state) => ({
  multilinefields: state.multilinefields,
  todos: state.todos,
});

export default connect(mapStateToProps)(UniqueFieldControl);
