export const CLEAR_CACHE = 'CLEAR_CACHE';
export const ELLIPSE_SIZE = 20;
export const WINDOW_WIDTH_DROPDOWN_ICONS_MIN = 1150;
export const HANSOFT = 'Hansoft';
export const PRODUCT = HANSOFT;
export const SERVICE = HANSOFT + ' Web Service';
export const REMOVE_FIELD = 'REMOVE_FIELD';
export const POINTS_MAX = 1000000;
export const POINTS_MIN = 0;
export const ESTIMATEDDAYS_MAX = 10000;
export const SERVICE_VERSION = 'service_version';
