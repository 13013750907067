import React from 'react';
import { connect } from 'react-redux';

import './taskcreate.scss';

import {
  createTask,
  subscribeWithParams,
  unsubscribeFromSubscriptionId,
} from '../../DDPJS/DDPJS';
import SingleSelectField from '../Fields/components/SingleSelect';
import { getProjectOptions } from '../../reducers/project.reducer';
import { CloseIcon, getEnumIcon } from '../../images/images';
import MultilineEditor from '../ItemDetail/MultiLine/MultilineEditor';

class TaskCreateModal extends React.Component {
  selectFirstWorkflow() {
    const workflows = this.props.workflows.filter(
      (workflow) =>
        workflow.$ProjectID === this.state.realProjectId &&
        workflow.fields.WorkflowType === 'workflow' &&
        workflow.fields.IsDefaultQAWorkflow,
    );
    if (workflows.length === 0) return;

    this.setState({
      workflowId: workflows[0].$ID,
    });
  }

  constructor(props) {
    super(props);
    this.descriptionElementRef = null;
  }

  state = {
    projectsSubscriptionId: subscribeWithParams('Projects', [], {
      onReady: () => {
        const firstProject = this.props.projects[0];
        this.setState({
          projectId: parseInt(firstProject.QA),
          realProjectId: parseInt(firstProject.id),
          projectWorkflowsSubscriptionId: subscribeWithParams(
            'ProjectWorkflows',
            { projectID: firstProject.id },
            {
              onReady: () => {
                this.selectFirstWorkflow();
              },
            },
          ),
        });
      },
    }),
    projectWorkflowsSubscriptionId: null,
    realProjectId: null,
    projectId: null,
    workflowId: null,
    creating: false,
    taskName: '',
    detailedDescription: '',
  };

  componentWillUnmount() {
    unsubscribeFromSubscriptionId(this.state.projectsSubscriptionId);
    unsubscribeFromSubscriptionId(this.state.projectWorkflowsSubscriptionId);
  }

  render() {
    const selectedProject = this.props.projects.find(
      (project) => project.id === this.state.projectId,
    );
    const selectedWorkflow = this.props.workflows.find(
      (workflow) =>
        workflow.$ProjectID === this.state.realProjectId &&
        workflow.$ID === this.state.workflowId,
    );
    let workflowOptions = this.props.workflows
      .filter(
        (workflow) =>
          workflow.$ProjectID === this.state.realProjectId &&
          workflow.fields.WorkflowType === 'workflow',
      )
      .map((workflow) => {
        return {
          icon: getEnumIcon(workflow.fields.Icon),
          value: workflow.$ID,
          text: workflow.fields.Name,
          selected: workflow.$ID === this.state.workflowId,
        };
      });

    workflowOptions = workflowOptions.sort(function (workflowA, workflowB) {
      const workflowAName = workflowA.text.toUpperCase();
      const workflowBName = workflowB.text.toUpperCase();

      if (workflowAName < workflowBName) return -1;
      else if (workflowAName > workflowBName) return 1;

      return 0;
    });

    return (
      <div className="modal-background taskcreate js-taskcreate">
        <div className="heading">
          <div>Create bug</div>
          <div
            onClick={() => {
              this.props.onCancel();
            }}
          >
            <CloseIcon />
          </div>
        </div>
        <div className="modal-content">
          <div className="field">
            <div className="label">Project</div>
            <SingleSelectField
              text={
                selectedProject ? `${selectedProject.Name}` : 'Select a project'
              }
              onSelectionChanged={(newValue) => {
                const project = this.props.projects.find(
                  (project) => project.id === newValue,
                );
                const realProjectId = parseInt(project.id);

                if (realProjectId === this.state.realProjectId) return;

                unsubscribeFromSubscriptionId(
                  this.state.projectWorkflowsSubscriptionId,
                );

                this.setState({
                  projectId: parseInt(project.QA),
                  realProjectId: realProjectId,
                  projectWorkflowsSubscriptionId: subscribeWithParams(
                    'ProjectWorkflows',
                    { projectID: project.id },
                    {
                      onReady: () => {
                        this.selectFirstWorkflow();
                      },
                    },
                  ),
                  workflowId: null,
                });
              }}
              options={getProjectOptions(this.props.projects, [], 'id')}
            />
          </div>
          <div className="field">
            <div className="label">Workflow</div>
            <SingleSelectField
              icon={
                selectedWorkflow
                  ? getEnumIcon(selectedWorkflow.fields.Icon)
                  : null
              }
              text={
                selectedWorkflow
                  ? `${selectedWorkflow.fields.Name}`
                  : 'Select a workflow'
              }
              onSelectionChanged={(workflowId) => {
                this.setState({
                  workflowId: workflowId,
                });
              }}
              options={workflowOptions}
            />
          </div>
          <div className="divider" />
          <div className="field">
            <div className="label">Name</div>
            <input
              className="js-task-name"
              value={this.state.taskName}
              onChange={(event) => {
                this.setState({
                  taskName: event.target.value,
                });
              }}
            />
          </div>
          <div className="field">
            <div className="label">Detailed Description</div>
            <MultilineEditor
              ref={(element) => (this.descriptionElementRef = element)}
              id={null}
              fieldID={'detailed-description'}
              fieldValue={this.state.detailedDescription}
              onBlur={(taggedText) => {
                this.setState({
                  detailedDescription: taggedText,
                });
              }}
            />
          </div>
          <div className="buttons">
            <button
              className="js-create"
              disabled={this.state.creating || this.state.projectId === null}
              onClick={() => {
                let detailedDescription = this.state.detailedDescription;
                if (this.descriptionElementRef)
                  detailedDescription = this.descriptionElementRef.getContentsAsTaggedText();

                this.setState({
                  creating: true,
                });

                createTask(
                  {
                    projectId: this.state.projectId,
                    fields: {
                      Description: this.state.taskName,
                      Workflow: parseInt(this.state.workflowId),
                      DetailedDescription: detailedDescription,
                    },
                  },
                  {
                    onComplete: (message) => {
                      this.setState({
                        creating: false,
                      });

                      if (message.result.success)
                        this.props.onCreated(message.result.createdTaskId);
                    },
                  },
                );
              }}
            >
              Create bug
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  projects: state.projects,
  workflows: state.workflows,
});

export default connect(mapStateToProps)(TaskCreateModal);
