import assignIn from 'lodash/merge';

import {
  resourceConstants,
  resourceUserTypes,
} from '../constants/resource.constants';
import { extractFromString } from '../helpers/projectid_parser';
import { CLEAR_CACHE } from '../constants/general.constants';
import { store } from '../helpers/store';
import { getProjectInfo } from './project.reducer';

function buildObject(resource, id, fields) {
  let newObject = {
    ...resource,
    id: id,
  };
  assignIn(newObject, fields);

  newObject.isNormalUser = function () {
    if ('Type' in this) {
      return this.Type === resourceUserTypes.EPM_ResourceType_Normal;
    }
    return false;
  };
  newObject.isGhostUser = function () {
    if ('Type' in this) {
      return this.Type === resourceUserTypes.EPM_ResourceType_Ghost;
    }
    return false;
  };
  newObject.isSDKUser = function () {
    if ('Type' in this) {
      return this.Type === resourceUserTypes.EPM_ResourceType_SDK;
    }
    return false;
  };
  newObject.isQAUser = function () {
    if ('Type' in this) {
      return this.Type === resourceUserTypes.EPM_ResourceType_QA_Account;
    }
    return false;
  };
  newObject.isSharedUser = function () {
    if ('Type' in this) {
      return this.Type === resourceUserTypes.EPM_ResourceType_Share;
    }
    return false;
  };

  return newObject;
}

const resources = (state = [], action) => {
  let projectID;
  if ('collection' in action)
    projectID = extractFromString('ProjectResources_', action.collection);
  switch (action.type) {
    case resourceConstants.ADD_RESOURCE:
      // TODO: find a solution for this rule
      // eslint-disable-next-line no-case-declarations
      let myResource = { projectID: projectID };
      return [...state, buildObject(myResource, action.id, action.fields)];
    case resourceConstants.UPDATE_RESOURCE:
      return state.map((resource) => {
        return resource.id === action.id && resource.projectID === projectID
          ? buildObject(resource, action.id, action.fields)
          : resource;
      });
    case resourceConstants.REMOVE_RESOURCE:
      // TODO: find a solution for this rule
      // eslint-disable-next-line no-case-declarations
      const myIndex = state.findIndex(
        (resource) =>
          resource.id === action.id && resource.projectID === projectID,
      );
      if (myIndex >= 0) {
        return [...state.slice(0, myIndex), ...state.slice(myIndex + 1)];
      } else {
        return state;
      }
    case resourceConstants.RESET:
    case CLEAR_CACHE:
      state.length = 0;
      return state;
    default:
      return state;
  }
};

export default resources;

export function findResource(projectID, resourceID) {
  const state = store.getState();
  let sResourceID = '';

  if (typeof resourceID === 'string') {
    sResourceID = resourceID;
  } else if (typeof resourceID === 'number') {
    sResourceID = resourceID.toString();
  }

  const projectData = getProjectInfo(projectID);
  if (projectData[1] === null) {
    return [];
  }

  return state.resources.find(
    (resource) =>
      resource.projectID === projectData[1].id && resource.id === sResourceID,
  );
}

// I'm usings an [{ID: ?}, ...] format to match resources defined in sprints
export function getResourceArrayForProject(projectID) {
  const state = store.getState();
  let myArray = [];

  const projectData = getProjectInfo(projectID);

  if (projectData[1] === null) {
    return [];
  }

  state.resources.map((resource) =>
    resource.projectID === projectData[1].id
      ? myArray.push({ ID: parseInt(resource.id, 10) })
      : null,
  );

  return myArray;
}
