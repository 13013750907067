import React from 'react';

import SingleSelect from '../components/SingleSelect';
import BaseField from '../BaseField';
import { formatYYYYMMDD } from '../../../helpers/date_time_format';
import {
  setField,
  subscribeWithParams,
  unsubscribeFromSubscriptionId,
} from '../../../DDPJS/DDPJS';
import {
  getMilestoneFromID,
  getSortedMilestoneList,
} from '../../../helpers/milestones';

export default class MilestoneFieldContainer extends BaseField {
  subcriptionParameters = {
    projectID: this.props.task.$ProjectID,
    findText: '',
  };

  constructor(props) {
    super(props);
    this.onSelectionChanged = this.onSelectionChanged.bind(this);
    this.state = {
      subscriptionId: subscribeWithParams(
        'ProjectMilestones',
        this.subcriptionParameters,
      ),
    };
  }

  componentWillUnmount() {
    if (this.state.subscriptionId)
      unsubscribeFromSubscriptionId(this.state.subscriptionId);
  }

  onSelectionChanged(newValues) {
    if (this.readOnlyFlag()) return;

    if (newValues.indexOf(-1) !== -1) newValues = [];
    else if (newValues.indexOf(-128) !== -1) newValues = [-128];

    setField(this.getFieldDefinition().id, this.getItemID(), newValues);
  }

  onFindDataChanged(newFindText) {
    this.onFindTextChanged(
      newFindText,
      'ProjectMilestones',
      this.subcriptionParameters,
    );
  }

  render() {
    if (!this.getFieldDefinition()) return null;

    let currentValues = this.getValue() || [];
    let displayValueParts = [];
    let options = [];

    let currentValueMilestones = [];
    for (let milestoneID of currentValues) {
      if (milestoneID === -128) {
        currentValueMilestones.push({
          fields: {
            Name: 'No release',
          },
        });
      } else {
        let milestone = getMilestoneFromID(milestoneID, this.props.milestones);
        if (milestone) currentValueMilestones.push(milestone);
      }
    }

    currentValueMilestones = getSortedMilestoneList(currentValueMilestones);

    for (let milestone of currentValueMilestones)
      displayValueParts.push(milestone.fields.Name);

    let milestoneOptions = [];
    for (let milestone of this.props.milestones) {
      if (milestone.$ProjectID !== this.getPlanningProjectID()) continue;

      milestoneOptions.push(milestone);
    }

    milestoneOptions = getSortedMilestoneList(milestoneOptions);

    options.push({
      value: -1,
      selected: currentValues.length === 0,
      text: '(Inherit from parent)',
      exclusive: true,
    });

    options.push({
      value: -128,
      selected: currentValues.indexOf(-128) >= 0,
      text: 'No release',
      exclusive: true,
    });

    milestoneOptions.forEach((milestone) => {
      const milestoneDate = new Date(milestone.fields.Date.$date);
      const dateAsString = formatYYYYMMDD(milestoneDate);

      options.push({
        value: parseInt(milestone.$ID),
        selected: currentValues.indexOf(parseInt(milestone.$ID)) !== -1,
        text: milestone.fields.Name + ' (' + dateAsString + ')',
      });
    });

    return (
      <SingleSelect
        subscriptionId={this.state.subscriptionId}
        fieldName={this.getFieldDefinition().DisplayName}
        disabled={this.readOnlyFlag()}
        isRequiredField={this.isWorkflowRequiredField()}
        options={options}
        icon={null}
        text={displayValueParts.join('; ')}
        multiSelection={true}
        onSelectionChanged={this.onSelectionChanged}
        findData={{
          onFindTextChanged: (newFindText) => {
            this.onFindDataChanged(newFindText);
          },
        }}
      />
    );
  }
}
