import React from 'react';
import { connect } from 'react-redux';
import { Modal, Image } from 'semantic-ui-react';

import {
  WarningStyle,
  InfoStyle,
  InfoIconStyle,
  WarningIconStyle,
} from './MessageStyles';
import { MsgLevelEnum, returnErrorMessage } from './MessageIndex';
import { clearErrorMessage } from './MessageUtilities';
import { ImgInfoMessage, ImgWarningMessage } from '../../images/images';

class MessageField extends React.Component {
  onCloseModal() {
    clearErrorMessage();
  }

  render() {
    return (
      <div>
        <Modal
          open={this.props.errormessage.iserror}
          dimmer={true}
          onClose={this.onCloseModal}
          closeIcon
        >
          <Modal.Content
            image
            style={dialogStyle(this.props.errormessage.msgLevel)}
          >
            <Image
              wrapped
              fluid
              verticalAlign="middle"
              style={iconStyle(this.props.errormessage.msgLevel)}
              src={icon(this.props.errormessage.msgLevel)}
            />
            <Modal.Description style={{ paddingLeft: '10px' }}>
              <p>{returnErrorMessage(this.props.errormessage.messageID)}</p>
            </Modal.Description>
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

function dialogStyle(msgLevel) {
  if (msgLevel === MsgLevelEnum.WARNING) {
    return WarningStyle;
  } else {
    return InfoStyle;
  }
}

function icon(msgLevel) {
  if (msgLevel === MsgLevelEnum.WARNING) {
    return ImgWarningMessage;
  } else {
    return ImgInfoMessage;
  }
}

function iconStyle(msgLevel) {
  if (msgLevel === MsgLevelEnum.WARNING) {
    return WarningIconStyle;
  } else {
    return InfoIconStyle;
  }
}

const mapStateToProps = (state) => ({
  errormessage: state.errormessage,
});

export default connect(mapStateToProps)(MessageField);
