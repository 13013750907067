import React from 'react';

import { formatYYYYMMDD } from '../../helpers/date_time_format';
import {
  getMilestoneFromID,
  getSortedMilestoneList,
} from '../../helpers/milestones';

import { ImgReleaseOrange } from '../../images/images';
import RelationList from './components/RelationList';

export default class ReleaseTagControl extends React.Component {
  render() {
    if (!('task' in this.props)) return null;

    let { LinkedToMilestone } = this.props.task.fields;
    if (!LinkedToMilestone || LinkedToMilestone.length === 0) return null;

    let milestoneValues = [];
    for (const item of LinkedToMilestone) {
      milestoneValues.push(getMilestoneFromID(item, this.props.milestones));
    }

    let displayValues = [];
    milestoneValues = getSortedMilestoneList(milestoneValues);

    milestoneValues.forEach((milestone) => {
      if (milestone) {
        const milestoneDate = new Date(milestone.fields.Date.$date);
        const dateAsString =
          milestone.fields.$ID === -128 ? null : formatYYYYMMDD(milestoneDate);

        displayValues.push({
          icon: ImgReleaseOrange,
          text: milestone.fields.Name,
          subText: dateAsString,
        });
      }
    });

    return <RelationList icon={ImgReleaseOrange} items={displayValues} />;
  }
}
