const COPYRIGHT_YEAR = new Date().getFullYear();

export const stringConstants = {
  SUPPORT_EMAIL: 'support@hansoft.com',
  PRODUCT_URL: 'https://www.perforce.com/products/hansoft',
  PRODUCT_URL_DISPLAY: 'www.perforce.com',
  COPYRIGHT:
    'Copyright ' +
    String.fromCharCode(169) +
    COPYRIGHT_YEAR +
    ' Hansoft Technologies AB, a Perforce company. All rights reserved.',
  WEB_SERVICE_VERSION: 'Hansoft web service ',
  CONTACT_SUPPORT: 'Contact Support',
  TIME_SPENT_COLUMN: 'Time spent',
  PERFORCE_HANSOFT_HELP_LINK:
    'https://help.perforce.com/hansoft/current/web/index.htm',
};
