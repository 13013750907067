import React, { Component } from 'react';
import { connect } from 'react-redux';

import ItemDetail from './ItemDetail';
import MessageDialog from '../MessageDialog/MessageDialog';
import { triggerMessageDialog } from '../MessageDialog/MessageUtilities';
import TitleBar from '../TitleBar/TitleBar';

class IDLayout extends Component {
  constructor(props) {
    super(props);
  }

  componentDidUpdate() {
    if (this.props.errormessage.iserror)
      triggerMessageDialog(this.props.errormessage.iserror);
  }

  componentDidMount() {
    triggerMessageDialog(this.props.errormessage.iserror);
  }

  render() {
    return (
      <div style={{ height: '100%' }}>
        <MessageDialog />
        <TitleBar
          {...this.props}
          callbackFromParent={this.toggleVisibility}
          backToList={true}
        />
        {this.props.appState.databaseGUIDHash ? (
          <ItemDetail {...this.props} />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentuser: state.currentuser,
  errormessage: state.errormessage,
  appState: state.appState,
});

export default connect(mapStateToProps)(IDLayout);
