import { determineSortDate } from '../reducers/todos.reducer';
import { dueDateConstants } from '../constants/todo.constants';
import { DueDates } from '../enums';

export const dueDateGrouping = <T>(todo: T): DueDates => {
  let today12am = new Date();
  today12am.setUTCHours(0);
  today12am.setUTCMinutes(0);
  today12am.setUTCSeconds(0);
  today12am.setUTCMilliseconds(0);
  const msToday = today12am.getTime();

  const sortDate = determineSortDate(todo);

  if (sortDate < msToday) return dueDateConstants.PAST_DUE;
  else if (sortDate === msToday) return dueDateConstants.TODAY;
  else {
    const msDayLength = 60 * 60 * 24 * 1000;
    let thisWeekDelta = (6 - today12am.getDay()) * msDayLength;
    let nextWeekDelta = thisWeekDelta + 7 * msDayLength;
    if (sortDate < thisWeekDelta + msToday) {
      return dueDateConstants.THIS_WEEK;
    } else if (sortDate < nextWeekDelta + msToday) {
      return dueDateConstants.NEXT_WEEK;
    } else {
      return dueDateConstants.LATER;
    }
  }
};
