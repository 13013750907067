import assignIn from 'lodash/merge';

import { workflowConstants } from '../constants/workflow.constants';
import { CLEAR_CACHE } from '../constants/general.constants';
import { extractFromString } from '../helpers/projectid_parser';
import { store } from '../helpers/store';
import { isValidObject } from '../helpers/validation_functions';

function buildObject(workflow, fields) {
  let newObject = {
    ...workflow,
    fields: {},
  };
  assignIn(newObject.fields, fields);

  newObject.hideItemStatus = function () {
    if ('HideItemStatus' in this.fields) {
      return this.fields.HideItemStatus;
    }

    return false;
  };

  return newObject;
}

const workflows = (state = [], action) => {
  let projectID;
  if ('collection' in action)
    projectID = extractFromString('ProjectWorkflows_', action.collection);
  switch (action.type) {
    case workflowConstants.ADD_WORKFLOW:
      // TODO: find a solution for this rule
      // eslint-disable-next-line no-case-declarations
      let myWorkflow = { $ProjectID: projectID, $ID: action.id };
      return [...state, buildObject(myWorkflow, action.fields)];
    case workflowConstants.UPDATE_WORKFLOW:
      return state.map((workflow) => {
        return workflow.$ID === action.id && workflow.$ProjectID === projectID
          ? buildObject(workflow, action.fields)
          : workflow;
      });
    case workflowConstants.REMOVE_WORKFLOW:
      // TODO: find a solution for this rule
      // eslint-disable-next-line no-case-declarations
      const myindex = state.findIndex(
        (workflow) =>
          workflow.$ID === action.id && workflow.$ProjectID === projectID,
      );
      if (myindex >= 0) {
        return [...state.slice(0, myindex), ...state.slice(myindex + 1)];
      } else {
        return state;
      }
    case workflowConstants.RESET:
    case CLEAR_CACHE:
      state.length = 0;
      return state;
    default:
      return state;
  }
};

export default workflows;

export function findWorkflow(projectID, workflowID) {
  const state = store.getState();
  let nProjectID = 0;
  let sWorkflowID = '';

  if (typeof projectID === 'string') {
    nProjectID = parseInt(projectID, 10);
  } else if (typeof projectID === 'number') {
    nProjectID = projectID;
  }

  if (typeof workflowID === 'string') {
    sWorkflowID = workflowID;
  } else if (typeof workflowID === 'number') {
    sWorkflowID = workflowID.toString();
  }

  return state.workflows.find(
    (workflow) =>
      workflow.$ProjectID === nProjectID && workflow.$ID === sWorkflowID,
  );
}

export function getMappingObject(workflow, statusValue, workflowValue) {
  let myMapping = null;
  const workflowObject = workflow.fields.WorkflowObjects.find(
    (object) => object.ID === workflowValue,
  );
  if (isValidObject(workflowObject)) {
    if (isValidObject(workflowObject.StatusToWorkflowStatuses)) {
      const mapping = workflowObject.StatusToWorkflowStatuses.find(
        (map) => map.StatusID === statusValue,
      );
      if (isValidObject(mapping)) myMapping = mapping;
    }
  }
  return myMapping;
}

export function getConnectedStatuses(workflow, workflowValue) {
  let myConnectedStatuses = null;
  if (!isValidObject(workflow)) {
    return myConnectedStatuses;
  }
  const workflowObject = workflow.fields.WorkflowObjects.find(
    (object) => object.ID === workflowValue,
  );
  if (isValidObject(workflowObject)) {
    myConnectedStatuses = workflowObject.ConnectedStatuses;
  }
  return myConnectedStatuses;
}

export function getStatusToWorkflowStatuses(workflow, workflowValue) {
  let myStatusToWorkflowStatuses = null;
  const workflowObject = workflow.fields.WorkflowObjects.find(
    (object) => object.ID === workflowValue,
  );
  if (isValidObject(workflowObject)) {
    if (isValidObject(workflowObject.StatusToWorkflowStatuses)) {
      myStatusToWorkflowStatuses = workflowObject.StatusToWorkflowStatuses;
    }
  }
  return myStatusToWorkflowStatuses;
}

function getWorkflowObject(projectID, workflowID, wfstateID) {
  var wfObject = null;
  const myWorkflow = findWorkflow(projectID, workflowID);
  if (isValidObject(myWorkflow)) {
    if (isValidObject(myWorkflow.fields.WorkflowObjects)) {
      wfObject = myWorkflow.fields.WorkflowObjects.find(
        function (wfobj) {
          return wfobj.ID === this.wfstateID;
        },
        { wfstateID: wfstateID },
      );
    }
  }
  return wfObject;
}

export function cannotSetToNotAssigned(projectID, workflowID, wfstateID) {
  const myWorkflowObject = getWorkflowObject(projectID, workflowID, wfstateID);
  if (myWorkflowObject && 'CannotSetNotAssigned' in myWorkflowObject) {
    return myWorkflowObject.CannotSetNotAssigned;
  } else {
    return false;
  }
}

export function hideInToDoList(projectID, workflowID, wfstateID) {
  const myWorkflowObject = getWorkflowObject(projectID, workflowID, wfstateID);
  if (myWorkflowObject && 'HideInToDoList' in myWorkflowObject) {
    return myWorkflowObject.HideInToDoList;
  } else {
    return false;
  }
}
