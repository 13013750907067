import assignIn from 'lodash/merge';

import { projectConstants } from '../constants/project.constants';
import { extractFromString } from '../helpers/projectid_parser';
import { CLEAR_CACHE } from '../constants/general.constants';
import { store } from '../helpers/store';

export function getProjectOptions(projects, selectedOptions, valueKey) {
  function sortByProjectName(first, second) {
    if (first.getSortName().toUpperCase() < second.getSortName().toUpperCase())
      return -1;
    if (first.getSortName().toUpperCase() > second.getSortName().toUpperCase())
      return 1;
  }

  return projects
    .sort(sortByProjectName)
    .filter(function (project) {
      return project.Type === 'Planning';
    })
    .map((project) => {
      return {
        value: project[valueKey],
        text: project.Name,
        selected: selectedOptions.indexOf(project.Name) !== -1,
      };
    });
}

function buildObject(sprint, id, fields) {
  let newProject = {
    ...sprint,
    id: id,
  };
  assignIn(newProject, fields);

  newProject.canChangePriorityInToDo = function () {
    if ('Settings' in this) {
      if (Array.isArray(this.Settings) && this.Settings.length > 0) {
        if ('CanChangePriorityInToDo' in this.Settings[0]) {
          return this.Settings[0].CanChangePriorityInToDo;
        }
      }
    }

    return true;
  };

  newProject.getSortName = function () {
    return 'SortName' in this && this.SortName.length > 0
      ? this.SortName
      : this.Name;
  };

  return newProject;
}

const projects = (state = [], action) => {
  let projectID;
  if ('collection' in action)
    projectID = extractFromString('ProjectMeta_', action.collection);
  switch (action.type) {
    case projectConstants.ADD_PROJECT:
      // TODO: find a solution for this rule
      // eslint-disable-next-line no-case-declarations
      let myProject = {};
      return [...state, buildObject(myProject, projectID, action.fields)];
    case projectConstants.UPDATE_PROJECT:
      return state.map((project) => {
        return project.id === projectID
          ? buildObject(project, projectID, action.fields)
          : project;
      });
    case projectConstants.REMOVE_PROJECT:
      // TODO: find a solution for this rule
      // eslint-disable-next-line no-case-declarations
      const myindex = state.findIndex((project) => project.id === projectID);
      if (myindex >= 0) {
        return [...state.slice(0, myindex), ...state.slice(myindex + 1)];
      } else {
        return state;
      }
    case projectConstants.RESET:
    case CLEAR_CACHE:
      state.length = 0;
      return state;
    default:
      return state;
  }
};

export default projects;

export function getProjectInfo(projectID) {
  const state = store.getState();

  let nProjectID = 0;
  let sProjectID = '';

  if (typeof projectID === 'string') {
    nProjectID = parseInt(projectID, 10);
    sProjectID = projectID;
  } else if (typeof projectID === 'number') {
    nProjectID = projectID;
    sProjectID = projectID.toString();
  }

  const actualProject = state.projects.find(
    (project) => project.id === nProjectID,
  );
  let planningProject = null;

  if (actualProject) {
    if (actualProject.Type === 'Planning') {
      planningProject = actualProject;
    } else if (actualProject.Type === 'QA') {
      planningProject = state.projects.find(
        (project) => project.Type === 'Planning' && project.QA === sProjectID,
      );
    } else if (actualProject.Type === 'Backlog') {
      planningProject = state.projects.find(
        (project) =>
          project.Type === 'Planning' && project.Backlog === sProjectID,
      );
    }

    return [actualProject, planningProject ? planningProject : null];
  } else {
    return [null, null];
  }
}
