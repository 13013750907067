import { store } from '../helpers/store';
import { stateConstants } from '../constants/state.constants';
import { determineSortDate } from '../reducers/todos.reducer';
import findIndex from 'lodash/findIndex';
import { hideInToDoList } from '../reducers/workflow.reducer';
import flattenDeep from 'lodash/flattenDeep';

export const filterService = {
  getFilteredTodos,
};

function sortTodos(first, second, customPriorityFields) {
  if (first.DueDateGroup < second.DueDateGroup) return -1;
  if (first.DueDateGroup > second.DueDateGroup) return 1;

  const taskHasCustomPriority = (task) => {
    return {
      id: customPriorityFields.find(
        (field) => task.fields[field.id] !== undefined,
      )?.id,
      Enum: customPriorityFields.find(
        (field) => task.fields[field.id] !== undefined,
      )?.Enum,
    };
  };

  const getCustomPriority = (task) => {
    const reversedPriority = taskHasCustomPriority(task)
      .Enum.concat([])
      .reverse();

    return (
      reversedPriority.findIndex(
        (customPriority) =>
          customPriority[0] === task.fields[taskHasCustomPriority(task).id],
      ) +
      (7 - taskHasCustomPriority(task).Enum.length)
    );
  };

  const getPriority = (task) =>
    task.fields[taskHasCustomPriority(task).id]
      ? getCustomPriority(task)
      : task.getPriority();

  const firstPriority = getPriority(first);
  const secondPriority = getPriority(second);

  if (firstPriority || secondPriority) {
    if (!firstPriority && secondPriority) return 1;
    if (!secondPriority && firstPriority) return -1;
    if (firstPriority > secondPriority) return -1;
    if (firstPriority < secondPriority) return 1;
  }

  const firstDueDate = determineSortDate(first);
  const secondDueDate = determineSortDate(second);
  if (firstDueDate < secondDueDate) return -1;
  if (firstDueDate > secondDueDate) return 1;

  if (parseInt(first.$ID, 10) < parseInt(second.$ID, 10)) return -1;
  if (parseInt(first.$ID, 10) > parseInt(second.$ID, 10)) return 1;

  return 0;
}

function stringSearch(object, searchValue) {
  let found = false;
  for (var property in object.fields) {
    if (Object.prototype.hasOwnProperty.call(object.fields, property)) {
      if (typeof object.fields[property] === 'string') {
        if (
          object.fields[property]
            .toUpperCase()
            .includes(searchValue.toUpperCase())
        )
          found = true;
      }
    }
    if (found) break;
  }
  return found;
}

const getCustomPriorityFields = (projects, singlelinefields) => {
  const customPriorityFields = [
    ...new Set(
      flattenDeep(
        projects.map((project) =>
          project.Settings.map(
            (setting) => setting.HashForPriorityInToDo,
          ).filter((priorityInToDo) => priorityInToDo !== '0'),
        ),
      ),
    ),
  ];

  return singlelinefields.filter((field) =>
    customPriorityFields.includes(field.id),
  );
};

function getFilteredTodos(todos, filter, projects, singlelinefields) {
  let mytodos = todos;
  const state = store.getState();
  if (state.collapseState.iscollapsed) {
    filter = {
      duedatefilter: [],
      statusfilter: [],
      priorityfilter: [],
      projectfilter: [],
      stringfilter: '',
    };
  }

  mytodos = mytodos.filter((todo) => {
    return todo.getProperty('WorkflowStatus')
      ? !hideInToDoList(
          todo.$ProjectID,
          todo.getProperty('Workflow'),
          todo.getProperty('WorkflowStatus'),
        )
      : true;
  });
  if (filter.duedatefilter && filter.duedatefilter.length > 0) {
    mytodos = mytodos.filter((todo) => {
      return (
        findIndex(filter.duedatefilter, function (o) {
          return o === todo.DueDateGroup;
        }) >= 0
      );
    });
  }
  if (filter.statusfilter && filter.statusfilter.length > 0) {
    mytodos = mytodos.filter((todo) => {
      return (
        findIndex(filter.statusfilter, function (o) {
          return o === todo.getProperty('Status');
        }) >= 0
      );
    });
  }
  if (filter.priorityfilter && filter.priorityfilter.length > 0) {
    mytodos = mytodos.filter((todo) => {
      let myPriorityField = 'SprintPriority';
      if (
        todo.getProperty('BugPriority') !== null &&
        todo.getProperty('SprintPriority') === null
      ) {
        myPriorityField = 'BugPriority';
      }
      return (
        findIndex(filter.priorityfilter, function (o) {
          return o === todo.getProperty(myPriorityField);
        }) >= 0
      );
    });
  }
  if (filter.projectfilter && filter.projectfilter.length > 0) {
    const state = store.getState();
    const matchingProjects = state.projects.filter(
      (project) =>
        findIndex(filter.projectfilter, function (o) {
          return o === project.Name;
        }) >= 0,
    );
    let projectIDArray = [];
    if (matchingProjects.length > 0) {
      for (const matchingProject of matchingProjects) {
        projectIDArray.push(matchingProject.id.toString());
      }
    }
    mytodos = mytodos.filter((todo) => {
      return projectIDArray.indexOf(todo.$ProjectID) >= 0;
    });
  }
  if (filter.stringfilter && filter.stringfilter !== '') {
    mytodos = mytodos.filter((todo) => {
      return stringSearch(todo, filter.stringfilter);
    });
  }
  mytodos.sort((firstToDo, secondToDo) =>
    sortTodos(
      firstToDo,
      secondToDo,
      getCustomPriorityFields(projects, singlelinefields),
    ),
  );
  if (!state.appState.loadingMode && state.appState.filterMode) {
    store.dispatch({ type: stateConstants.CLEAR_FILTER_MODE });
  }
  return mytodos;
}
