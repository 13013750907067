import { findFieldByProject } from '../../../reducers/singleline.reducer';
import { getPlanningProjectID } from './getPlanningProjectID';
import { Task } from '../../../interfaces';

export const getFieldDefinition = (fieldId: string, task: Task) => {
  if (task) {
    return findFieldByProject(
      fieldId,
      task.$ProjectID,
      getPlanningProjectID(task),
    );
  } else return {};
};
