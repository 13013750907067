import { dashboardConstants } from '../constants/dashboard.constants';
import { CLEAR_CACHE } from '../constants/general.constants';

const dashboards = (state = [], action) => {
  switch (action.type) {
    case dashboardConstants.ADD_DASHBOARD: {
      return [
        ...state,
        {
          id: parseInt(action.id),
          ...action.fields,
        },
      ];
    }
    case dashboardConstants.UPDATE_DASHBOARD: {
      const updatedDashboardId = parseInt(action.id);
      return state.map((dashboard) => {
        if (dashboard.id === updatedDashboardId) {
          return {
            id: updatedDashboardId,
            ...action.fields,
          };
        }

        return dashboard;
      });
    }
    case dashboardConstants.REMOVE_DASHBOARD: {
      const removedDashboardId = parseInt(action.id);
      const dashboardIndex = state.findIndex(
        (dashboard) => dashboard.id === removedDashboardId,
      );
      if (dashboardIndex >= 0) {
        return [
          ...state.slice(0, dashboardIndex),
          ...state.slice(dashboardIndex + 1),
        ];
      } else {
        return state;
      }
    }
    case CLEAR_CACHE: {
      return [];
    }
    default: {
      return state;
    }
  }
};

export default dashboards;
