export const stateConstants = {
  SET_CONNECTED: 'SET_CONNECTED_STATUS',
  CLEAR_CONNECTED: 'CLEAR_CONNECTED_STATUS',
  SET_CURRENT_TASK_ID: 'SET_CURRENT_TASK_ID',
  CLEAR_CURRENT_TASK_ID: 'CLEAR_CURRENT_TASK_ID',
  SET_LOADING_MODE: 'SET_LOADING_MODE',
  CLEAR_LOADING_MODE: 'CLEAR_LOADING_MODE',
  TOGGLE_LOADING_MODE: 'TOGGLE_LOADING_MODE',
  SET_FILTER_MODE: 'SET_FILTER_MODE',
  CLEAR_FILTER_MODE: 'CLEAR_FILTER_MODE',
  TOGGLE_FILTER_MODE: 'TOGGLE_FILTER_MODE',
  SET_DATABASE_GUID_HASH: 'SET_DATABASE_GUID_HASH',
  SET_SERVER_VERSION: 'SET_SERVER_VERSION',
  CLEAR_SERVER_VERSION: 'CLEAR_SERVER_VERSION',
  SET_CURRENT_DASHBOARD_ID: 'SET_CURRENT_DASHBOARD_ID',
  SET_FEATURE_TOGGLES: 'SET_FEATURE_TOGGLES',
  SET_DASHBOARDS_ACCESS: 'SET_DASHBOARDS_ACCESS',
};
