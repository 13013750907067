import React from 'react';
import { connect } from 'react-redux';

import './integrationHeader.scss';

class IntegrationHeader extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div id="integrationHeaderRoot">
        <br />
        <div className="integrationHeader">
          {this.props.shared.integration.data.header}
        </div>
        <br />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentuser: state.currentuser,
  errormessage: state.errormessage,
  appState: state.appState,
});

export default connect(mapStateToProps)(IntegrationHeader);
