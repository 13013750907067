import React from 'react';

import BuiltInSingleSelect from '../components/BuiltInSingleSelect';
import { getEnumIcon } from '../../../images/images';
import { setField } from '../../../DDPJS/DDPJS';

export default class EnumFieldContainer extends React.Component {
  getEnumList = (field) => {
    if (field.getFieldDefinition().Type === 'Enum')
      return field.getFieldDefinition().Enum;
    else if (field.getFieldDefinition().Type === 'InlineEnum')
      return field.getValue().Enum;
  };

  getIcon = (field, $FieldID) => {
    const fieldEnum = this.getEnumList(field);
    for (const item of fieldEnum) {
      if (item[0] === field.state.value)
        return getEnumIcon(this.getIconIndex(item, $FieldID), $FieldID);
    }
  };

  getText = (field) => {
    if (!('Enum' in field.getFieldDefinition())) return '';

    const fieldEnum = this.getEnumList(field);
    for (const item of fieldEnum) {
      if (item[0] === field.state.value) return item[1];
    }

    return this.props.isCustomPriority ? '(Not set)' : '';
  };

  getIconIndex = (enumObject, $FieldID) => {
    if ($FieldID.indexOf('CC_') === 0) {
      return enumObject[2];
    } else {
      return enumObject[0];
    }
  };

  onSelectionChanged = (field, newValue) => {
    if (field.readOnlyFlag()) return;

    field.setState({ newValue });

    let sValue = newValue;
    if (typeof newValue !== 'string') sValue = newValue.toString();

    setField(field.getFieldDefinition().id, field.getItemID(), sValue);
  };

  getOptions = (field) => {
    const fieldEnum = this.getEnumList(field);
    let options = [];

    for (const item of fieldEnum) {
      const value = item[0];

      options.push({
        value: value,
        selected: value === field.state.value,
        image: getEnumIcon(
          this.getIconIndex(item, this.props.$FieldID),
          this.props.$FieldID,
        ),
        text: item[1],
      });
    }

    return options;
  };

  render() {
    return (
      <BuiltInSingleSelect
        task={this.props.task}
        $FieldID={this.props.$FieldID}
        getOptions={this.getOptions}
        getIcon={(field) => {
          return this.getIcon(field, this.props.$FieldID);
        }}
        getText={this.getText}
        isListView={this.props.isListView}
        onSelectionChanged={this.onSelectionChanged}
      />
    );
  }
}
