import React from 'react';
import ChartContainer from '../containers/Chart';

import './charts.scss';

class Charts extends React.Component {
  render() {
    const mainTheme = this.props.mainTheme;
    const theme = this.props.theme;

    return (
      <div className={['charts', this.props.layout.toLowerCase()].join(' ')}>
        {this.props.layoutColumns.map((column, index) => {
          return (
            <div key={index} className="column">
              {column.charts.map((chart) => {
                return (
                  <ChartContainer
                    key={chart.id}
                    {...chart}
                    mainTheme={mainTheme}
                    theme={theme}
                  />
                );
              })}
            </div>
          );
        })}
      </div>
    );
  }
}

export default Charts;
