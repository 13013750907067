import { filterConstants } from '../constants/filter.constants';
import { CLEAR_CACHE } from '../constants/general.constants';

const filteroptions = (state = baseFilterState, action) => {
  switch (action.type) {
    case filterConstants.CLEAR_ALL:
    case CLEAR_CACHE:
      return {
        duedatefilter: [],
        statusfilter: [],
        priorityfilter: [],
        projectfilter: [],
        stringfilter: '',
      };
    case filterConstants.DUEDATE_FILTER:
      return { ...state, duedatefilter: action.duedatefilter };
    case filterConstants.STATUS_FILTER:
      return { ...state, statusfilter: action.statusfilter };
    case filterConstants.PRIORITY_FILTER:
      return { ...state, priorityfilter: action.priorityfilter };
    case filterConstants.PROJECT_FILTER:
      return { ...state, projectfilter: action.projectfilter };
    case filterConstants.STRING_FILTER:
      return { ...state, stringfilter: action.stringfilter };
    default:
      return state;
  }
};

const baseFilterState = {
  duedatefilter: [],
  statusfilter: [],
  priorityfilter: [],
  projectfilter: [],
  stringfilter: '',
};

export default filteroptions;
