import React, { useState } from 'react';
import { Image, Modal } from 'semantic-ui-react';

import { store } from '../../helpers/store';
import { determineSortDate } from '../../reducers/todos.reducer';
import { resourceEntryTypes } from '../../constants/resource.constants';
import { getEnumIcon, ImgNoComment } from '../../images/images';
import {
  formatYYYYMMDD,
  formatTime24Hour,
} from '../../helpers/date_time_format';
import PriorityFieldContainer from '../Fields/containers/PriorityField';
import { fieldIsEnabled } from '../../reducers/singleline.reducer';
import { getProjectInfo } from '../../reducers/project.reducer';
import StatusFieldContainer from '../Fields/containers/StatusField';
import WorkflowStatusFieldContainer from '../Fields/containers/WorkflowStatusField';
import * as VC from '../../services/versioncontrol';

import TextField from './fields/TextField';
import NumberField from '../Fields/NumberField';
import { stringConstants } from '../../constants/strings.constants';
import { TimeSpentListField } from '../Fields/TimeSpentListField';
import EnumFieldContainer from '../Fields/containers/EnumField';

function DateField(enabledField, task) {
  let value = task.fields[enabledField.id]
    ? task.fields[enabledField.id].$date
    : -1;
  return (
    <td key={enabledField.id} className="field">
      {value !== -1 ? formatYYYYMMDD(new Date(value)) : null}
    </td>
  );
}

function DateTimeField(enabledField, task) {
  let value = task.fields[enabledField.id]
    ? task.fields[enabledField.id].$date
    : -1;

  return (
    <td key={enabledField.id} className="field">
      {value !== -1
        ? `${formatYYYYMMDD(new Date(value))} ${formatTime24Hour(
            new Date(value),
          )}`
        : null}
    </td>
  );
}

function IntegrationField(integrationData, task, databaseGUIDHash) {
  let btnId = 'AddButton' + task.$ID;

  const ind = integrationData.hansoftIds.indexOf(task.$ID);
  let initInnerText = 'Add';
  let initClassName = 'integration-button is-activated';
  if (ind !== -1) {
    initInnerText = 'Remove';
    initClassName = 'integration-button is-deactivated';
  }

  return (
    <td key={task.$ID}>
      <div
        className={initClassName}
        id={btnId}
        onClick={() => {
          let elem = document.getElementById(btnId);
          if (elem.innerHTML === 'Add') {
            elem.innerHTML = 'Remove';
            elem.className = 'integration-button is-deactivated';
            integrationData.onBind(task, databaseGUIDHash);
          } else {
            elem.innerHTML = 'Add';
            elem.className = 'integration-button is-activated';
            integrationData.onUnbind(task, databaseGUIDHash);
          }
        }}
      >
        {initInnerText}
      </div>
    </td>
  );
}

function DescriptionField(
  enabledField,
  databaseGUIDHash,
  showCoverImages,
  task,
) {
  let description = task.fields.Description.trim();
  if (!description) description = '(No name)';

  const [keyImagePreviewOpen, setKeyImagePreviewOpen] = useState(false);

  const projectData = getProjectInfo(task.$ProjectID);
  const projectName = projectData[0] ? projectData[0].Name : '';
  const pipelineName = task.fields.LinkedToPipelineTaskName || '';
  const mainImageFileInfo = VC.mapMainImageToInfo(task);

  return (
    <td key={enabledField.id} className="field is-disabled">
      <div
        style={{
          display: 'flex',
          flexFlow: 'row nowrap',
          alignItems: 'center',
        }}
      >
        {showCoverImages ? (
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              height: 100,
              minHeight: 100,
              width: 124,
              minWidth: 124,
              paddingRight: 16,
            }}
          >
            {mainImageFileInfo ? (
              <Image
                inline
                title="Click to view full size"
                style={{ cursor: 'pointer', maxHeight: '100%' }}
                src={mainImageFileInfo.url}
                onClick={() => setKeyImagePreviewOpen(true)}
              />
            ) : (
              <Image inline src={ImgNoComment} />
            )}
          </div>
        ) : null}
        <div className="description-container">
          <div className="description">
            <div className="project">
              {pipelineName ? `${projectName}: ${pipelineName}` : projectName}
            </div>
            <a
              className="taskname js-task-name"
              href={`/task/${databaseGUIDHash}/${task.$ID}`}
            >
              {description}
            </a>
          </div>
        </div>
        {mainImageFileInfo ? (
          <Modal
            className="lightbox"
            closeIcon
            open={keyImagePreviewOpen}
            onClose={() => setKeyImagePreviewOpen(false)}
          >
            <Modal.Content image>
              <Image centered src={mainImageFileInfo.url} />
            </Modal.Content>
          </Modal>
        ) : null}
      </div>
    </td>
  );
}

function HyperlinkField(enabledField, task) {
  const value = task.fields[enabledField.id];
  let linkTo =
    typeof value === 'object' ? JSON.stringify(value, null, 2) : value;

  return (
    <td key={enabledField.id} className="field">
      <a href={linkTo} target="_blank noopener noreferrer">
        {linkTo}
      </a>
    </td>
  );
}

function ResourceAllocationsField(enabledField, task) {
  let displayValueParts = [];
  if (task.fields[enabledField.id]) {
    const state = store.getState();
    const resources = state.resources.filter(
      (resource) => resource.$projectID === task.$projectID,
    );
    for (const resourceAllocation of task.fields[enabledField.id]) {
      const resource = resources.find(
        (resource) => parseInt(resource.id) === resourceAllocation[0],
      );
      if (resource) displayValueParts.push(resource.Name);
    }
  }

  return (
    <td key={enabledField.id} className="field">
      {displayValueParts.join('; ')}
    </td>
  );
}

function DueDateField(enabledField, task) {
  let formattedDueDate = '';

  const dueDate = determineSortDate(task);
  if (dueDate !== Number.MAX_VALUE)
    formattedDueDate = formatYYYYMMDD(new Date(dueDate));

  return (
    <td key={enabledField.id} className="field is-disabled DueDate">
      {formattedDueDate}
    </td>
  );
}

function ResourceField(enabledField, task) {
  let displayValueParts = [];
  if (task.fields[enabledField.id]) {
    // FIXME: No state, please.
    const state = store.getState();
    const resources = state.resources.filter(
      (resource) => parseInt(resource.$projectID) === task.$projectID,
    );
    const groups = state.groups;

    for (const resourceAllocation of task.fields[enabledField.id]) {
      if (resourceAllocation[0] === resourceEntryTypes.NORMAL) {
        const resource = resources.find(
          (resource) => parseInt(resource.id) === resourceAllocation[1],
        );
        if (resource) displayValueParts.push(resource.Name);
      } else if (resourceAllocation[0] === resourceEntryTypes.GROUP) {
        const group = groups.find(
          (group) => parseInt(group.id) === resourceAllocation[1],
        );
        if (group) displayValueParts.push(group.Name);
      }
    }
  }

  return (
    <td key={enabledField.id} className="field">
      {displayValueParts.join('; ')}
    </td>
  );
}

function EnumField(enabledField, task) {
  let values = task.fields[enabledField.id] || [];
  if (!Array.isArray(values)) values = [values];

  values = values.sort(function (valueA, valueB) {
    const sortValueA = enabledField.values[valueA].sortOrder;
    const sortValueB = enabledField.values[valueB].sortOrder;
    if (sortValueA < sortValueB) return -1;
    if (sortValueB > sortValueA) return 1;
    return 0;
  });

  return (
    <td key={enabledField.id} className="field">
      <div className="values">
        {values.map((value, index) =>
          enabledField.values[value] ? (
            <div key={index} className="value">
              <div className="icon">
                <img
                  src={getEnumIcon(
                    enabledField.values[value].icon,
                    enabledField.id,
                  )}
                  alt=""
                />
              </div>
              <div>{enabledField.values[value].text}</div>
            </div>
          ) : null,
        )}
      </div>
    </td>
  );
}

const taskHasCustomPriority = (task, customPriorityFields) => {
  return customPriorityFields.find(
    (field) => task.fields[field.id] !== undefined,
  )?.id;
};

function Task(props) {
  function getFieldComponent(enabledField) {
    if (enabledField.id === 'Description')
      return DescriptionField(
        enabledField,
        props.databaseGUIDHash,
        props.showCoverImages,
        props.task,
      );
    else if (enabledField.id === 'Integration')
      return IntegrationField(
        props.integrationData,
        props.task,
        props.databaseGUIDHash,
      );
    else if (enabledField.id === 'DueDate') {
      return DueDateField(enabledField, props.task);
    } else if (enabledField.id === 'Priority') {
      return (
        <td key={enabledField.id} className="field no-padding Priority">
          {taskHasCustomPriority(props.task, props.customPriorityFields) ? (
            <EnumFieldContainer
              task={props.task}
              $FieldID={taskHasCustomPriority(
                props.task,
                props.customPriorityFields,
              )}
              isListView
              isCustomPriority
            />
          ) : props.task.hasPriority() ? (
            <PriorityFieldContainer
              task={props.task}
              $FieldID={
                fieldIsEnabled('BugPriority', props.task)[0]
                  ? 'BugPriority'
                  : 'SprintPriority'
              }
              isListView
            />
          ) : null}
        </td>
      );
    } else if (enabledField.id === 'Status') {
      if (!props.task.hasStatus()) {
        return <td key={enabledField.id} className="field is-disabled" />;
      } else if (props.task.hasWorkflow()) {
        const projectInfo = getProjectInfo(props.task.$ProjectID);
        if (projectInfo[1] === undefined) return null;

        return (
          <td key={enabledField.id} className="field no-padding">
            <WorkflowStatusFieldContainer
              task={props.task}
              $FieldID={'Status'}
              isListView={true}
            />
          </td>
        );
      }

      return (
        <td key={enabledField.id} className="field no-padding">
          <StatusFieldContainer task={props.task} isListView={true} />
        </td>
      );
    } else if (enabledField.type === 'MultiEnum' && enabledField.values)
      return EnumField(enabledField, props.task);
    else if (enabledField.type === 'Enum' && enabledField.values)
      return EnumField(enabledField, props.task);
    else if (
      enabledField.type === 'Text' ||
      enabledField.type === 'String' ||
      (enabledField.type === 'Integer' && enabledField.readOnly) ||
      (enabledField.type === 'Float' && enabledField.readOnly)
    )
      return TextField(enabledField, props.task);
    else if (enabledField.type === 'DateTime')
      return DateField(enabledField, props.task);
    else if (enabledField.type === 'DateTimeTime')
      return DateTimeField(enabledField, props.task);
    else if (enabledField.type === 'Integer' || enabledField.type === 'Float') {
      return (
        <td key={enabledField.id} className="field">
          <NumberField
            task={props.task}
            $FieldID={enabledField.id}
            isListView={true}
          />
        </td>
      );
    } else if (enabledField.type === 'Hyperlink')
      return HyperlinkField(enabledField, props.task);
    else if (enabledField.type === 'ResourceAllocations')
      return ResourceAllocationsField(enabledField, props.task);
    else if (enabledField.type === 'Resources')
      return ResourceField(enabledField, props.task);
    else if (enabledField.type === 'Hours') {
      if (enabledField.id === 'WorkRemaining') {
        const projectData = getProjectInfo(props.task.$ProjectID);
        const projectType = projectData[0].Type;
        if (projectType === 'Planning' && !props.task.isInSprint())
          return <td key={enabledField.id} className="field is-disabled" />;
      }

      if (enabledField.name === stringConstants.TIME_SPENT_COLUMN) {
        return (
          <td key={enabledField.id} className="field">
            <TimeSpentListField task={props.task} fieldID={enabledField.id} />
          </td>
        );
      }

      return (
        <td key={enabledField.id} className="field">
          <NumberField
            task={props.task}
            $FieldID={enabledField.id}
            isListView={true}
          />
        </td>
      );
    } else {
      return (
        <td
          key={enabledField.id}
          className="field is-disabled is-notimplemented"
        />
      );
    }
  }

  return (
    <tr>
      {props.enabledFields.map((enabledField) => {
        if (!enabledField || !enabledField.id) return <td />;

        return getFieldComponent(enabledField);
      })}
    </tr>
  );
}

export default Task;
