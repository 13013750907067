import React from 'react';
import { connect } from 'react-redux';
import isEqual from 'lodash/isEqual';

import BaseField from './BaseField';
import {
  setField,
  unsubscribeFromSubscriptionId,
  subscribeWithParams,
} from '../../DDPJS/DDPJS';
import { cannotSetToNotAssigned } from '../../reducers/workflow.reducer';
import { findSprint } from '../../reducers/sprint.reducer';
import {
  findResource,
  getResourceArrayForProject,
} from '../../reducers/resource.reducer';
import { MessageEnum, MsgLevelEnum } from '../MessageDialog/MessageIndex';
import { setErrorMessage } from '../MessageDialog/MessageUtilities';
import { getProjectInfo } from '../../reducers/project.reducer';
import { ImgUserGreen } from '../../images/images';
import SingleSelectField from './components/SingleSelect';
import { isValidObject } from '../../helpers/validation_functions';
import { findFieldByProject } from '../../reducers/singleline.reducer';

export function sortUsers(first, second) {
  const firstSortName = first.sortValue ? first.sortValue : first.text;
  const secondSortName = second.sortValue ? second.sortValue : second.text;

  if (firstSortName.toUpperCase() < secondSortName.toUpperCase()) return -1;
  if (firstSortName.toUpperCase() > secondSortName.toUpperCase()) return 1;
  return 0;
}

class AllocationsField extends BaseField {
  subcriptionParameters = {
    projectID: this.props.task.$ProjectID,
    sprintID: -1,
    bIncludeGroups: true,
  };

  state = {
    value: createValueArray(this.getValue()),
    currentID: this.props.task.$ID,
    subscriptionId: subscribeWithParams(
      'ProjectResources',
      this.subcriptionParameters,
    ),
  };

  componentDidUpdate(prevProps, prevState) {
    if (isEqual(prevState.value, createValueArray(this.getValue())) === false) {
      this.setState({ value: createValueArray(this.getValue()) });
    }
    if (prevState.currentID !== this.props.task.$ID) {
      this.setState({ currentID: this.props.task.$ID });
    }
  }

  componentWillUnmount() {
    if (this.state.subscriptionId)
      unsubscribeFromSubscriptionId(this.state.subscriptionId);
  }

  onChange = (event, value) => {
    if (this.readOnlyFlag()) {
      return;
    }

    const bCannotSetToNotAssigned = this.props.task.getProperty(
      'WorkflowStatus',
    )
      ? cannotSetToNotAssigned(
          this.getProjectID(),
          this.props.task.getProperty('Workflow'),
          this.props.task.getProperty('WorkflowStatus'),
        )
      : false;

    if (value.length === 0 && bCannotSetToNotAssigned) {
      setErrorMessage(
        MsgLevelEnum.INFORMATION,
        MessageEnum.WORKFLOW_PROHIBITS_NOT_ASSIGNED,
      );
      return;
    }

    this.setState({ value: value });

    const allocationArray = createAllocationArray(value, this.getValue());
    setField(this.getFieldDefinition().id, this.getItemID(), allocationArray);
  };

  render() {
    if (!isValidObject(this.getFieldDefinition())) {
      return null;
    }

    if (this.state.currentID !== this.props.task.$ID) {
      return null;
    }

    let users = [];
    let myResources = [];

    if (this.props.task.isInSprint()) {
      const projectData = getProjectInfo(this.props.task.$ProjectID);
      if (projectData[1] !== null) {
        const mySprint = findSprint(
          projectData[1].id,
          this.props.task.getSprintID(),
        );
        if (isValidObject(mySprint)) {
          myResources = mySprint.getResources();
        }
      }
    } else {
      myResources = getResourceArrayForProject(this.props.task.$ProjectID);
    }

    const currentValue = this.state.value;
    const bIsQATask = this.props.task.isQATask();

    myResources.forEach((item) => {
      const myResource = findResource(this.props.task.$ProjectID, item.ID);
      if (!isValidObject(myResource) || myResource.isSDKUser()) {
        return;
      }
      if (!bIsQATask && myResource.isQAUser()) {
        return;
      }
      const value = parseInt(myResource.id, 10);

      users.push({
        image: `${window.location.origin}/versioncontrol/Avatars/${myResource.id}/Avatar_64.PNG`,
        fallbackImage: ImgUserGreen,
        selected: currentValue.indexOf(value) !== -1,
        text: myResource.Name,
        sortValue: myResource.SortName,
        value: value,
      });
    });

    let displayValue = '';
    currentValue.forEach((item) => {
      const myResource = findResource(this.props.task.$ProjectID, item);
      if (!isValidObject(myResource)) {
        return;
      }
      if (!displayValue) displayValue = myResource.Name;
      else displayValue += '; ' + myResource.Name;
    });

    users.sort(sortUsers);

    let displayName = this.getFieldDefinition().DisplayName;
    if (this.props.task.fields.CommittedToProjectID !== undefined) {
      let committedProjectField = findFieldByProject(
        this.props.$FieldID,
        this.getPlanningProjectID(),
      );
      if (committedProjectField)
        displayName = committedProjectField.DisplayName;
    }

    return (
      <SingleSelectField
        subscriptionId={this.state.subscriptionId}
        text={displayValue}
        fieldName={displayName}
        disabled={this.readOnlyFlag()}
        isRequiredField={this.isWorkflowRequiredField()}
        multiSelection={true}
        onSelectionChanged={(newValues) => {
          this.onChange(null, newValues);
        }}
        findData={{
          onFindTextChanged: (newFindText) => {
            this.onFindTextChanged(
              newFindText,
              'ProjectResources',
              this.subcriptionParameters,
            );
          },
        }}
        options={users}
      />
    );
  }
}

function createValueArray(valueArray) {
  let value = [];
  for (const item of valueArray) {
    value.push(item[0]);
  }
  return value;
}

function createAllocationArray(valueArray, propsArray) {
  let value = [];
  for (const item of valueArray) {
    value.push([item, getOriginalAllocationValue(item, propsArray)]);
  }
  return value;
}

function getOriginalAllocationValue(id, array) {
  let allocation = 100;
  for (const item of array) {
    if (item[0] === id) {
      return item[1];
    }
  }
  return allocation;
}

const mapStateToProps = (state, props) => {
  return {
    resources: state.resources,
    sprints: state.sprints,
    assignments: props.task.getProperty(props.$FieldID), // Ensure shallow equals re-render
  };
};

export default connect(mapStateToProps)(AllocationsField);
