import React from 'react';

import BaseField from './BaseField';
import { setField } from '../../DDPJS/DDPJS';
import ItemDetailsField from './components/ItemDetailsField';
import { isValidObject } from '../../helpers/validation_functions';

export default class StringField extends BaseField {
  constructor(props) {
    super(props);
    this.onBlur = this.onBlur.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  state = {
    originalValue: this.getValue(),
    value: this.getValue(),
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.originalValue !== this.getValue()) {
      this.setState({ originalValue: this.getValue(), value: this.getValue() });
    }
  }

  onChange = (event) => {
    if (this.readOnlyFlag()) return;

    this.setState({ value: event.target.value });
  };

  onBlur = () => {
    if (this.readOnlyFlag()) {
      return;
    }

    if (this.state.value === this.state.originalValue) {
      return;
    }

    this.setState({ originalValue: this.state.value });

    setField(this.getFieldDefinition().id, this.getItemID(), this.state.value);
  };

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.onBlur(event);
    }
  };

  render() {
    if (!isValidObject(this.getFieldDefinition())) {
      return null;
    }

    return (
      <ItemDetailsField
        fieldName={this.getFieldDefinition().DisplayName}
        isRequiredField={this.isWorkflowRequiredField()}
      >
        <input
          readOnly={this.readOnlyFlag()}
          onChange={(event) => {
            this.onChange(event);
          }}
          onBlur={(event) => {
            this.onBlur(event);
          }}
          onKeyPress={this.handleKeyPress}
          value={this.state.value}
        />
      </ItemDetailsField>
    );
  }
}
