import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Dimmer, Loader } from 'semantic-ui-react';
import flattenDeep from 'lodash/flattenDeep';

import '../../semantic/dist/semantic.min.css';
import './ToDoList.scss';

import { getDueDateGroupName } from '../../constants/todo.constants';
import { filterService } from '../../services/filter.service';
import FilterBar from './FilterBar/FilterBar';
import TaskList from '../tasks/TaskList';
import { DueDates, MyWorkFields, MyWorkIgnoredFields } from '../../enums';
import { dueDateGrouping } from '../../helpers/grouping_functions';
import { stringConstants } from '../../constants/strings.constants';

class ToDoList extends PureComponent {
  constructor(props) {
    super(props);

    this.toggleVisibility = this.toggleVisibility.bind(this);
    this.tableRowClickFunc = this.tableRowClickFunc.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  state = {
    visible: false,
    height: 0,
    filterOptions: null,
  };

  toggleVisibility() {
    this.setState({ visible: !this.state.visible });
  }

  tableRowClickFunc() {
    this.toggleVisibility();
  }

  componentDidMount() {
    document.title = 'Hansoft';
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions, false);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions, false);
  }

  updateWindowDimensions() {
    let height = window.innerHeight;
    this.setState({ height: height });
  }

  filterTaskGroups() {
    let groups = Object.values(DueDates);

    return groups.map((group) => {
      return {
        name: getDueDateGroupName(group),
        tasks: this.props.todos.filter((todo) => {
          return dueDateGrouping(todo) === group;
        }),
      };
    });
  }

  getTimeSpentColumns() {
    const { projects, todos } = this.props;
    const timeSpentColumnsInProjects = [
      ...new Set(
        flattenDeep(
          projects.map((project) =>
            project.Settings.map(
              (setting) => setting.HashForTimeSpentInToDo,
            ).filter((timeSpentInTodo) => timeSpentInTodo !== '0'),
          ),
        ),
      ),
    ];

    if (
      timeSpentColumnsInProjects.length > 0 &&
      todos.find((todo) =>
        timeSpentColumnsInProjects.some((timeSpentColumn) =>
          Object.keys(todo.fields).includes(timeSpentColumn),
        ),
      )
    ) {
      return timeSpentColumnsInProjects;
    }
  }

  getCustomPriorityFields() {
    const { projects, singlelinefields } = this.props;

    const customPriorityFields = [
      ...new Set(
        flattenDeep(
          projects.map((project) =>
            project.Settings.map(
              (setting) => setting.HashForPriorityInToDo,
            ).filter((priorityInToDo) => priorityInToDo !== '0'),
          ),
        ),
      ),
    ];

    return singlelinefields.filter((field) =>
      customPriorityFields.includes(field.id),
    );
  }

  filterEnabledFields = () => {
    const { singlelinefields, shared } = this.props;
    const fieldIds = Object.values(MyWorkFields);

    if (this.getTimeSpentColumns()) {
      fieldIds.push(stringConstants.TIME_SPENT_COLUMN);
    }

    let uniqueFields = {};

    singlelinefields.forEach((field) => {
      if (fieldIds.indexOf(field.id) >= 0) {
        uniqueFields[field.id] = field;
      }
    });

    let enabledFields = [];

    const ignoredFields = Object.values(MyWorkIgnoredFields);

    // Special "computed" fields
    if (fieldIds.indexOf(MyWorkFields.DUE_DATE) !== -1) {
      enabledFields.push({
        id: MyWorkFields.DUE_DATE,
        name: 'Due Date',
        readOnly: true,
      });
    }

    if (fieldIds.indexOf(MyWorkFields.PRIORITY) !== -1) {
      enabledFields.push({
        id: MyWorkFields.PRIORITY,
        name: 'Priority',
      });
    }

    Object.keys(uniqueFields).forEach((uniqueField) => {
      const field = uniqueFields[uniqueField];
      let values = {};

      if (
        ignoredFields.indexOf(field.id) === -1 &&
        fieldIds.indexOf(field.id) !== -1
      ) {
        if (field.Enum) {
          Object.keys(field.Enum).forEach((uniqueFieldEnum) => {
            const fieldValue = field.Enum[uniqueFieldEnum];
            values[fieldValue[0]] = {
              icon:
                field.id.indexOf('CC_') === 0 ? fieldValue[2] : fieldValue[0],
              text: fieldValue[1],
              sortOrder: uniqueFieldEnum,
            };
          });
        }
        enabledFields.push({
          id: uniqueField,
          type: field.Type,
          name: field.DisplayName,
          values: Object.keys(values).length >= 1 ? values : null,
          readOnly: field.ReadOnly,
        });
      }
    });

    enabledFields = enabledFields.sort(function (fieldA, fieldB) {
      const fieldAIndex = fieldIds.indexOf(fieldA.id);
      const fieldBIndex = fieldIds.indexOf(fieldB.id);

      if (fieldAIndex < fieldBIndex) return -1;
      else if (fieldAIndex > fieldBIndex) return 1;
      return 0;
    });

    if (shared.integration.enabled) {
      enabledFields.splice(1, 0, {
        id: 'Integration',
        name: shared.integration.data.columnName,
      });
    }

    if (fieldIds.indexOf(stringConstants.TIME_SPENT_COLUMN) !== -1) {
      this.getTimeSpentColumns().forEach((column) =>
        enabledFields.push({
          id: column,
          name: stringConstants.TIME_SPENT_COLUMN,
          type: 'Hours',
          readOnly: false,
        }),
      );
    }

    return enabledFields;
  };

  render() {
    const { appState, shared, collapseState, integration } = this.props;
    const loadingState = appState.loadingMode || appState.filterMode;
    const showCoverImages = shared.showCoverImages;

    return (
      // eslint-disable-next-line react/no-string-refs
      <div className="todo" ref="MyWorkList">
        <Dimmer active={loadingState}>
          <Loader>Loading</Loader>
        </Dimmer>
        {collapseState.iscollapsed ? null : <FilterBar />}
        <TaskList
          databaseGUIDHash={appState.databaseGUIDHash}
          showCoverImages={showCoverImages}
          enabledFields={this.filterEnabledFields()}
          taskGroups={this.filterTaskGroups()}
          integration={integration}
          integrationData={shared.integration.data}
          customPriorityFields={this.getCustomPriorityFields()}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentuser: state.currentuser,
  collapseState: state.collapseState,
  appState: state.appState,
  filteroptions: state.filteroptions,
  todos: filterService.getFilteredTodos(
    state.todos,
    state.filteroptions,
    state.projects,
    state.singlelinefields,
  ),
  projects: state.projects,
  singlelinefields: state.singlelinefields,
  sprints: state.sprints,
});

export default connect(mapStateToProps)(ToDoList);
