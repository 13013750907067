import React from 'react';
import draftToHtml from 'draftjs-to-html';

import {
  formatYYYYMMDD,
  formatTime24Hour,
} from '../../../helpers/date_time_format';
import { convertTaggedTextToDraftjs } from '../../../services/TaggedTextToDraftjs';
import { getLoggedInResourceID } from '../../../DDPJS/DDPJS';

export default class CommentItem extends React.Component {
  renderTimeString(postedAt) {
    let date = new Date(postedAt);
    return formatTime24Hour(date);
  }

  renderDateString(postedAt) {
    let date = new Date(postedAt);
    return formatYYYYMMDD(date);
  }

  createMarkup() {
    function customEntityTransform(entity) {
      if (entity.type === 'IMAGE') {
        if (entity.data.src.indexOf(window.location.origin) === -1) {
          return `<a href="${entity.data.src}">${entity.data.src}</a>`;
        } else {
          return `<img src="${entity.data.src}" style="max-width: ${entity.data.width}; width: 100%;"/>`;
        }
      }
    }

    return {
      __html: draftToHtml(
        convertTaggedTextToDraftjs(
          this.props.Text,
          this.props.versionControlFileInfo,
        ),
        undefined,
        undefined,
        customEntityTransform,
      ),
    };
  }

  render() {
    const { PostedBy, PostedAt } = this.props;
    const timeString = this.renderTimeString(PostedAt.$date);
    const dateString = this.renderDateString(PostedAt.$date);

    return (
      <div className="commentitem-container">
        <div className="commentitem-details">
          <div className="commentitem-author">{PostedBy}</div>
          <div className="commentitem-date-text">
            {dateString} at {timeString}
          </div>
        </div>
        <div
          className="commentitem-content js-commentitem-content"
          dangerouslySetInnerHTML={this.createMarkup()}
        />
        {getLoggedInResourceID() === parseInt(this.props.PostedByID) ? (
          <div className="commentitem-edit" onClick={this.props.handler}>
            Edit
          </div>
        ) : null}
      </div>
    );
  }
}
