import React from 'react';
import { Loader } from 'semantic-ui-react';

import Charts from '../components/Charts';
import { ImgEmptyDashboard } from '../../../images/images';

class ChartsContainer extends React.Component {
  render() {
    if (!this.props.dashboard) return <Loader active />;

    let numCharts = 0;
    if (this.props.dashboard.layoutColumns) {
      for (const column of this.props.dashboard.layoutColumns)
        numCharts += column.charts.length;
    }

    if (!numCharts) {
      return (
        <div className="emptystate">
          <img className="icon" src={ImgEmptyDashboard} alt={''} />
          <div className="text">
            No charts have been created on this dashboard yet.
          </div>
        </div>
      );
    }

    return (
      <Charts
        layout={this.props.dashboard.layout}
        layoutColumns={this.props.dashboard.layoutColumns}
        pageId={this.props.dashboard.id}
        theme={this.props.dashboard.theme}
        mainTheme={this.props.theme}
      />
    );
  }
}

export default ChartsContainer;
