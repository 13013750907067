function isNull(object) {
  return object === null;
}

function isUndefined(object) {
  return typeof object === 'undefined';
}

export function isValidObject(object) {
  return !isUndefined(object) && !isNull(object);
}

export function isValidNumber(value) {
  const myValue = value.toString();
  return !!myValue.match(/^[-+]?[0-9]*$/);
}

export function isValidDecimal(value) {
  const myValue = value.toString();
  return !!myValue.match(/^[-+]?[0-9]*\.?[0-9]+$/);
}
