import { PRODUCT, SERVICE } from '../../constants/general.constants';
import { store } from '../../helpers/store';

export const MessageEnum = {
  CUSTOM_MESSAGE: -1,
  NO_ERROR: 0,
  NO_LONGER_ASSIGNED: 1,
  NO_LONGER_CONNECTED: 2,
  WORKFLOW_PROHIBITS_NOT_ASSIGNED: 3,
  SESSION_HAS_EXPIRED: 4,
  TEMP_DISABLED: 5,
  EMPTY_MESSAGE: 6,
  TASK_NOT_FOUND: 7,
};

export const MsgLevelEnum = {
  INFORMATION: 0,
  WARNING: 1,
};

const ENGLISH = 0; // Going to assume English for now, this is for future planning...
let currentLanguage = ENGLISH;

const messageArray = [
  [ENGLISH, MessageEnum.NO_ERROR, 'No error.'],
  [
    ENGLISH,
    MessageEnum.NO_LONGER_ASSIGNED,
    'The current item is no longer in your to-do list. For example, it may be completed, reassigned, hidden or deleted.',
  ],
  [
    ENGLISH,
    MessageEnum.NO_LONGER_CONNECTED,
    PRODUCT +
      ' is no longer connected to the ' +
      SERVICE +
      '. Please check with your administrator to determine if there is a problem.',
  ],
  [
    ENGLISH,
    MessageEnum.WORKFLOW_PROHIBITS_NOT_ASSIGNED,
    "Workflow rules do not allow the Assigned To field to be set to 'Not Assigned'",
  ],
  [
    ENGLISH,
    MessageEnum.SESSION_HAS_EXPIRED,
    'Login session has expired. Please log in to ' + PRODUCT + ' again.',
  ],
  [
    ENGLISH,
    MessageEnum.TEMP_DISABLED,
    'Access to Item Details has been temporarily disabled. Please contact Rikard Nilsson or Michael Tackett with questions.',
  ],
  [
    ENGLISH,
    MessageEnum.INVALID_HOURS_PER_DAY,
    'An invalid value for the estimated hours per day value was returned from the server.',
  ],
  [
    ENGLISH,
    MessageEnum.EMPTY_MESSAGE,
    'The comment has been deleted because it was empty.',
  ],
  [
    ENGLISH,
    MessageEnum.TASK_NOT_FOUND,
    'The task you are trying to view could not be found.',
  ],
];

export function returnErrorMessage(messageID) {
  let errorMessage = '';

  if (messageID === MessageEnum.CUSTOM_MESSAGE) {
    const state = store.getState();
    return state.errormessage.customMessage;
  }

  for (const item of messageArray) {
    if (item[0] === currentLanguage && item[1] === messageID) {
      return item[2];
    }
  }

  return errorMessage;
}
