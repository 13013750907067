export const resourceConstants = {
  ADD_RESOURCE: 'ADD_RESOURCE',
  UPDATE_RESOURCE: 'UPDATE_RESOURCE',
  REMOVE_RESOURCE: 'REMOVE_RESOURCE',
  RESET: 'RESET_RESOURCE',
};

export const resourceEntryTypes = {
  NORMAL: 1,
  GROUP: 2,
  OTHER: 3,
};

export const resourceUserTypes = {
  EPM_ResourceType_Normal: 1,
  EPM_ResourceType_Ghost: 2,
  EPM_ResourceType_SDK: 3,
  EPM_ResourceType_QA_Account: 4,
  EPM_ResourceType_Share: 5,
};
