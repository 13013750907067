import React from 'react';

import RelationList from '../ItemDetail/components/RelationList';
import { ImgChain } from '../../images/images';

export default class LinkedToField extends React.Component {
  constructor(props) {
    super(props);
    this.defaultText = this.defaultText.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value)
      this.setState({ value: this.props.value });
  }

  defaultText() {
    if (this.props.linkedTo && this.props.linkedTo.length > 0) {
      return this.props.linkedTo[0][1];
    } else {
      return '';
    }
  }

  render() {
    if (this.props.linkedTo) {
      let items = this.props.linkedTo.map((item) => {
        return {
          icon: ImgChain,
          text: item[1],
          link: item[2],
        };
      });

      if (items.length === 0) return null;

      return <RelationList icon={ImgChain} items={items} />;
    } else {
      return null;
    }
  }
}
