import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from '../reducers';

export const store = createStore(
  rootReducer,
  // eslint-disable-next-line no-undef
  process.env.NODE_ENV === 'production'
    ? applyMiddleware(thunkMiddleware)
    : applyMiddleware(thunkMiddleware, createLogger()),
);
