import assignIn from 'lodash/merge';

import { groupConstants } from '../constants/group.constants';
import { CLEAR_CACHE } from '../constants/general.constants';

function buildObject(group, id, fields) {
  let newObject = {
    ...group,
    id: id,
  };
  assignIn(newObject, fields);
  return newObject;
}

const resources = (state = [], action) => {
  switch (action.type) {
    case groupConstants.ADD_GROUP:
      // TODO: find a solution for this rule
      // eslint-disable-next-line no-case-declarations
      let myGroup = {};
      return [...state, buildObject(myGroup, action.id, action.fields)];
    case groupConstants.UPDATE_GROUP:
      return state.map((resource) => {
        return resource.id === action.id
          ? buildObject(resource, action.id, action.fields)
          : resource;
      });
    case groupConstants.REMOVE_GROUP:
      // TODO: find a solution for this rule
      // eslint-disable-next-line no-case-declarations
      const myIndex = state.findIndex((resource) => resource.id === action.id);
      if (myIndex >= 0) {
        return [...state.slice(0, myIndex), ...state.slice(myIndex + 1)];
      } else {
        return state;
      }
    case groupConstants.RESET:
    case CLEAR_CACHE:
      state.length = 0;
      return state;
    default:
      return state;
  }
};

export default resources;
