import React from 'react';
import { connect } from 'react-redux';

import './filterbar.scss';
import SingleSelectField from '../../Fields/components/SingleSelect';
import { filterConstants } from '../../../constants/filter.constants';
import {
  ImgNotDone,
  ImgInProgress,
  ImgBlocked,
  ImgToBeDeleted,
  ImgPriorityNone,
  ImgPriorityVeryLow,
  ImgPriorityLow,
  ImgPriorityMedium,
  ImgPriorityHigh,
  ImgPriorityVeryHigh,
} from '../../../images/images';
import {
  displayPriorityFilter,
  displayStatusFilter,
  displayProjectFilter,
  displayTimeFilter,
} from '../FilterDisplayControl';
import SearchField from '../../TitleBar/SearchField';
import { getProjectOptions } from '../../../reducers/project.reducer';

class FilterBar extends React.Component {
  render() {
    const dueFilterActive = this.props.filteroptions.duedatefilter.length >= 1;
    const priorityFilterActive =
      this.props.filteroptions.priorityfilter.length >= 1;
    const statusFilterActive =
      this.props.filteroptions.statusfilter.length >= 1;
    const projectFilterActive =
      this.props.filteroptions.projectfilter.length >= 1;

    return (
      <div className="filterbar">
        <SearchField />
        <div
          className={
            dueFilterActive ? 'filterbutton is-active' : 'filterbutton'
          }
        >
          <SingleSelectField
            text={
              dueFilterActive
                ? displayTimeFilter(this.props.filteroptions.duedatefilter)
                : 'Due date'
            }
            multiSelection={true}
            onSelectionChanged={(newValues) => {
              const message = {
                type: filterConstants.DUEDATE_FILTER,
                duedatefilter: newValues,
              };
              const { dispatch } = this.props;
              dispatch(message);
            }}
            options={[
              {
                value: 1,
                text: 'Past due',
                selected:
                  this.props.filteroptions.duedatefilter.indexOf(1) !== -1,
              },
              {
                value: 2,
                text: 'Today',
                selected:
                  this.props.filteroptions.duedatefilter.indexOf(2) !== -1,
              },
              {
                value: 3,
                text: 'This week',
                selected:
                  this.props.filteroptions.duedatefilter.indexOf(3) !== -1,
              },
              {
                value: 4,
                text: 'Next week',
                selected:
                  this.props.filteroptions.duedatefilter.indexOf(4) !== -1,
              },
              {
                value: 5,
                text: 'Later',
                selected:
                  this.props.filteroptions.duedatefilter.indexOf(5) !== -1,
              },
            ]}
          />
        </div>
        <div
          className={
            projectFilterActive ? 'filterbutton is-active' : 'filterbutton'
          }
        >
          <SingleSelectField
            text={
              projectFilterActive
                ? displayProjectFilter(this.props.filteroptions.projectfilter)
                : 'Project'
            }
            multiSelection={true}
            onSelectionChanged={(newValues) => {
              const message = {
                type: filterConstants.PROJECT_FILTER,
                projectfilter: newValues,
              };
              const { dispatch } = this.props;
              dispatch(message);
            }}
            options={getProjectOptions(
              this.props.projects,
              this.props.filteroptions.projectfilter,
              'Name',
            )}
          />
        </div>
        <div
          className={
            statusFilterActive ? 'filterbutton is-active' : 'filterbutton'
          }
        >
          <SingleSelectField
            text={
              statusFilterActive
                ? displayStatusFilter(this.props.filteroptions.statusfilter)
                : 'Status'
            }
            multiSelection={true}
            onSelectionChanged={(newValues) => {
              const message = {
                type: filterConstants.STATUS_FILTER,
                statusfilter: newValues,
              };
              const { dispatch } = this.props;
              dispatch(message);
            }}
            options={[
              {
                value: 5,
                image: ImgBlocked,
                text: 'Blocked',
                selected:
                  this.props.filteroptions.statusfilter.indexOf(5) !== -1,
              },
              {
                value: 3,
                image: ImgInProgress,
                text: 'In progress',
                selected:
                  this.props.filteroptions.statusfilter.indexOf(3) !== -1,
              },
              {
                value: 2,
                image: ImgNotDone,
                text: 'Not done',
                selected:
                  this.props.filteroptions.statusfilter.indexOf(2) !== -1,
              },
              {
                value: 6,
                image: ImgToBeDeleted,
                text: 'To be deleted',
                selected:
                  this.props.filteroptions.statusfilter.indexOf(6) !== -1,
              },
            ]}
          />
        </div>
        <div
          className={
            priorityFilterActive ? 'filterbutton is-active' : 'filterbutton'
          }
        >
          <SingleSelectField
            text={
              priorityFilterActive
                ? displayPriorityFilter(this.props.filteroptions.priorityfilter)
                : 'Priority'
            }
            multiSelection={true}
            onSelectionChanged={(newValues) => {
              const message = {
                type: filterConstants.PRIORITY_FILTER,
                priorityfilter: newValues,
              };
              const { dispatch } = this.props;
              dispatch(message);
            }}
            options={[
              {
                value: 6,
                image: ImgPriorityVeryHigh,
                text: 'Very high',
                selected:
                  this.props.filteroptions.priorityfilter.indexOf(6) !== -1,
              },
              {
                value: 5,
                image: ImgPriorityHigh,
                text: 'High',
                selected:
                  this.props.filteroptions.priorityfilter.indexOf(5) !== -1,
              },
              {
                value: 4,
                image: ImgPriorityMedium,
                text: 'Medium',
                selected:
                  this.props.filteroptions.priorityfilter.indexOf(4) !== -1,
              },
              {
                value: 3,
                image: ImgPriorityLow,
                text: 'Low',
                selected:
                  this.props.filteroptions.priorityfilter.indexOf(3) !== -1,
              },
              {
                value: 2,
                image: ImgPriorityVeryLow,
                text: 'Very low',
                selected:
                  this.props.filteroptions.priorityfilter.indexOf(2) !== -1,
              },
              {
                value: 1,
                image: ImgPriorityNone,
                text: '(No priority set)',
                selected:
                  this.props.filteroptions.priorityfilter.indexOf(1) !== -1,
              },
            ]}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  filteroptions: state.filteroptions,
  projects: state.projects,
});

export default connect(mapStateToProps)(FilterBar);
