import React, { Component } from 'react';
import Modal from 'react-modal';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { Dropdown } from 'semantic-ui-react';
import { history } from '../../helpers/history';

import {
  logoffHansoft,
  clearAuthToken,
  hasDashboardsAccess,
} from '../../DDPJS/DDPJS';
import BackLinkControl from '../ItemDetail/BackLinkControl';
import { deleteRefreshToken } from '../../tokens/refresh.token';
import { ImgLogoCollapsed, ImgFunnel } from '../../images/images';

import './TitleBar.scss';
import TaskCreateModal from '../tasks/TaskCreateModal';
import { getThemeName } from '../../App/App';
import { stringConstants } from '../../constants/strings.constants';
import { Routes } from '../../enums';

// TODO: Strongly type all the props.
interface IProps extends RouteComponentProps {
  shared: any;
  appState: any;
  currentuser: any;
  backToList: boolean;
  currentRoute: string;
  onToggleFilter(): void;
  resources: any[];
  collapseState: any;
}

interface IState {
  createTaskIsOpen: boolean;
  profileMenuOpen: boolean;
}

export class TitleBar extends Component<IProps> {
  state: IState = {
    profileMenuOpen: false,
    createTaskIsOpen: false,
  };

  constructor(props: IProps) {
    super(props);
    this.navigateToRoute = this.navigateToRoute.bind(this);
    const createTaskIsOpen: boolean =
      this.props.history.location.search.indexOf('create_task') > -1;

    this.state = {
      profileMenuOpen: false,
      createTaskIsOpen: createTaskIsOpen,
    };
  }

  onLogout = () => {
    logoffHansoft();
    clearAuthToken();
    deleteRefreshToken();
    this.props.history.push(Routes.LOGIN);
  };

  onHelpClick = () => {
    window.open(stringConstants.PERFORCE_HANSOFT_HELP_LINK);
  };

  navigateToRoute = (route: Routes) => {
    this.props.history.push(route);
  };

  navigateToRoot = () => {
    if (this.props.shared.integration.enabled) {
      this.navigateToRoute(this.props.shared.integration.data.redirectPath);
    } else {
      this.navigateToRoute(Routes.TODO_LIST);
    }
  };

  closeTaskModal = () => {
    this.setState({
      createTaskIsOpen: false,
    });
  };

  openTaskModal = () => {
    this.setState({
      createTaskIsOpen: true,
    });
  };

  onTaskCreation = (createdTaskId: string) => {
    this.setState({
      createTaskIsOpen: false,
    });

    if (createdTaskId)
      history.push(
        `/task/${this.props.appState.databaseGUIDHash}/${createdTaskId}`,
      );
  };

  render() {
    const {
      currentuser,
      backToList,
      currentRoute,
      onToggleFilter,
      shared,
    } = this.props;

    let username = currentuser.username;

    const resource: any = this.props.resources.find(
      function (iResource) {
        return iResource.Name.toUpperCase() === username.toUpperCase();
      },
      { username: currentuser.username },
    );
    if (typeof resource !== 'undefined') {
      username = resource.Name;
    }

    const filterIsOpen = !this.props.collapseState.iscollapsed;

    return (
      <div className="titlebar">
        <div className="title">
          <img className="logo" src={ImgLogoCollapsed} alt="" />
          {backToList ? (
            <BackLinkControl {...this.props} />
          ) : (
            <div className="navigation-list">
              <div
                className={`titlebar-button ${
                  currentRoute === 'todo' && 'is-active'
                } js-todolist`}
                onClick={this.navigateToRoot}
              >
                To-Do List
              </div>
              {hasDashboardsAccess() && (
                <div
                  className={`titlebar-button ${
                    currentRoute === 'dashboards' && 'is-active'
                  } js-dashboards`}
                  onClick={() => this.navigateToRoute(Routes.DASHBOARDS)}
                >
                  Dashboards
                </div>
              )}
              <div
                className="titlebar-button is-primary js-create-bug"
                onClick={this.openTaskModal}
              >
                New bug
              </div>
            </div>
          )}
        </div>
        <div className="controls">
          {onToggleFilter ? (
            <div className="titlebar-button" onClick={onToggleFilter}>
              <img src={ImgFunnel} alt="" />
              {filterIsOpen ? 'Close filter' : 'Filter'}
            </div>
          ) : null}
          <Dropdown className="profile-menu js-account-menu" text={username}>
            <Dropdown.Menu>
              x
              <Dropdown.Item
                className="js-menu-theme"
                onClick={shared.onToggleTheme}
                text="Change theme"
              />
              <Dropdown.Item
                className="js-menu-coverimages"
                onClick={shared.onToggleCoverImages}
                text={
                  shared.showCoverImages
                    ? 'Hide cover images'
                    : 'Show cover images'
                }
              />
              <Dropdown.Item onClick={this.onHelpClick} text="Help..." />
              <Dropdown.Divider />
              <Dropdown.Item
                className="js-menu-logout"
                onClick={this.onLogout}
                text="Log out"
              />
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <Modal
          isOpen={this.state.createTaskIsOpen}
          className={`theme--${getThemeName()}`}
          style={{
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.75)',
              padding: '64px 128px',
              overflow: 'auto',
              zIndex: 999,
            },
            content: {
              borderRadius: '8px',
              outline: 'none',
              maxWidth: '900px',
              margin: '0 auto',
            },
          }}
          onRequestClose={this.closeTaskModal}
        >
          <TaskCreateModal
            onCancel={this.closeTaskModal}
            onCreated={this.onTaskCreation}
          />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  appState: state.appState,
  currentuser: state.currentuser,
  collapseState: state.collapseState,
  filteroptions: state.filteroptions,
  resources: state.resources,
});

export default connect(mapStateToProps)(TitleBar);
