import React from 'react';
import { Image } from 'semantic-ui-react';

import ClickEditBaseField from '../Fields/ClickEditBaseField';
import { ImgEditComment } from '../../images/images';

export default class DescriptionControl extends ClickEditBaseField {
  getTitleString() {
    let description = this.getValue();

    if (description.length >= 256) {
      description = description.slice(0, 255);
      description += '...';
    }

    if (description.trim().length === 0) description = '(No name)';
    if ('ID' in this.props.task.fields)
      description =
        '#' + this.props.task.fields.ID.toString() + ' - ' + description;
    return description;
  }

  updateDocumentTitle() {
    const description = this.getValue();
    document.title = `${description || '(No name)'} - Hansoft`;
  }

  componentDidMount() {
    this.updateDocumentTitle();
  }

  componentDidUpdate() {
    this.updateDocumentTitle();
  }

  getViewMode() {
    return (
      <span style={TitleStyle}>
        <span className="js-task-name" style={{ marginRight: '5px' }}>
          {this.getTitleString()}
        </span>
        {this.readOnlyFlag() ? null : (
          <Image
            className="js-rename-task"
            style={EditTitleStyle}
            avatar
            src={ImgEditComment}
            onClick={(event) => {
              this.toggleEditMode(event);
            }}
          />
        )}
      </span>
    );
  }
}

const TitleStyle = {
  alignItems: 'center',
  fontFamily: 'Roboto',
  fontSize: '1.5rem',
};

const EditTitleStyle = {
  width: '16px',
  height: '16px',
  cursor: 'pointer',
  borderRadius: '0px',
};
