import React from 'react';
import PropTypes from 'prop-types';
import { Popup } from 'semantic-ui-react';

import Relation from './relation';

class RelationListPopup extends React.Component {
  constructor(props) {
    super(props);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target))
      this.props.onClose();
  }

  render() {
    return (
      <div
        className="relationlist-popup"
        ref={(element) => (this.wrapperRef = element)}
      >
        <div className="relationlist-items">
          {this.props.items.map((item, index) => (
            <Relation key={index} {...item} />
          ))}
        </div>
      </div>
    );
  }
}

export default class RelationList extends React.Component {
  constructor(props) {
    super(props);
    this.showRelations = this.showRelations.bind(this);
    this.state = {
      popupOpen: false,
    };
  }

  showRelations() {
    this.setState({
      popupOpen: true,
    });
  }

  render() {
    return (
      <div>
        <div
          ref={(element) => (this.relationListRef = element)}
          className="relationlist"
          onClick={() => this.showRelations()}
        >
          <img src={this.props.icon} alt=""></img>
        </div>
        <Popup
          open={this.state.popupOpen}
          content={
            <RelationListPopup
              items={this.props.items}
              onClose={() => this.setState({ popupOpen: false })}
            />
          }
          context={this.relationListRef}
          position="bottom center"
        />
      </div>
    );
  }
}

RelationList.propTypes = {
  icon: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      text: PropTypes.string,
      subText: PropTypes.string,
      link: PropTypes.string,
      download: PropTypes.bool,
    }),
  ),
};
