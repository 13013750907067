import React from 'react';
import { connect } from 'react-redux';

import StringField from '../../Fields/StringField';
import NumberField from '../../Fields/NumberField';
import DateTimeField from '../../Fields/DateTimeField';
import MultiEnumField from '../../Fields/MultiEnumField';
import ResourcesField from '../../Fields/ResourcesField';
import AllocationsField from '../../Fields/AllocationsField';
import HyperlinkField from '../../Fields/HyperlinkField';
import ReadOnlyField from '../../Fields/ReadOnlyField';
import { getProjectInfo } from '../../../reducers/project.reducer';
import { filterCoreFieldsByProject } from '../../../reducers/singleline.reducer';
import StatusFieldContainer from '../../Fields/containers/StatusField';
import PriorityFieldContainer from '../../Fields/containers/PriorityField';
import EnumFieldContainer from '../../Fields/containers/EnumField';
import MilestoneFieldContainer from '../../Fields/containers/MilestoneField';
import TimeZoneField from '../../Fields/TimeZoneField';
import WorkflowStatusFieldContainer from '../../Fields/containers/WorkflowStatusField';
import { isValidObject } from '../../../helpers/validation_functions';
import TimeSpent from '../../Fields/components/TimeSpent';

class SinglelineLayout extends React.Component {
  constructor(props) {
    super(props);
    this.renderField = this.renderField.bind(this);
    this.sortByFieldName = this.sortByFieldName.bind(this);
    this.buildFieldNameArray = this.buildFieldNameArray.bind(this);
  }

  state = {
    projectType: this.findProjectType(parseInt(this.props.task.$ProjectID, 10)),
    projectID: parseInt(this.props.task.$ProjectID, 10),
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.projectID !== parseInt(this.props.task.$ProjectID, 10))
      this.setState({ projectID: parseInt(this.props.task.$ProjectID, 10) });
  }

  findProjectType(projectID) {
    const projectData = getProjectInfo(projectID);

    if (projectData[0]) return projectData[0].Type;
    else return '';
  }

  renderField(fieldname, fields) {
    // QA
    // Status
    // Severity
    // BugPriority
    // ResourceAllocationFirst

    // Backlog
    // Status
    // Work Remaining
    // SprintPriority
    // ResourceAllocationFirst

    // Scheduled task
    // Status
    // ResourceAllocationFirst
    // TimeZoneStart
    // TimeZoneEnd

    const field = fields.find(
      function (field) {
        return field.id === this.fieldname;
      },
      { fieldname: fieldname },
    );
    const { task } = this.props;
    const projectData = getProjectInfo(this.state.projectID);
    const projectType = projectData[0].Type;

    // these are fields that may not have metadata depending on project type
    if (!isValidObject(field)) {
      if (fieldname === 'WorkRemaining') {
        if (projectData[1]) {
          return (
            <NumberField
              key={'WorkRemaining'}
              task={task}
              $FieldID={field.id}
            />
          );
        }
      } else if (fieldname === 'SprintPriority') {
        if (projectType === 'QA') {
          return null;
        }
        if (projectData[1]) {
          const sprintPriorityField = this.props.singlelinefields.find(
            function (field) {
              return (
                field.id === this.fieldname &&
                field.projectID === this.$ProjectID
              );
            },
            { fieldname: fieldname, $ProjectID: projectData[1].id },
          );
          return (
            <PriorityFieldContainer
              key={sprintPriorityField.id}
              task={task}
              $FieldID={field.id}
            />
          );
        }
      }
      console.log(
        'Missing field: ' +
          fieldname +
          ' for task id: ' +
          task.$ID +
          ' and project id: ' +
          task.$ProjectID,
      );
      return null;
    }

    // do not display these fields in the web todo
    let noDisplayFields = [
      'Comment',
      'LinkedToSprint',
      'Description',
      'WorkflowStatus',
      'Type',
      'LockedType',
      'Archived',
    ];
    if (projectType === 'QA') {
      noDisplayFields.push(
        'Status',
        'Severity',
        'SprintPriority',
        'BugPriority',
        'LinkedTo',
        'ResourceAllocationFirst',
      );
    } else if (
      projectType === 'Backlog' &&
      task.fields.CommittedToProjectID === undefined
    ) {
      noDisplayFields.push(
        'Status',
        'BacklogPriority',
        'SprintPriority',
        'LinkedTo',
        'WorkRemaining',
        'Duration',
      );
    } else if (
      projectType === 'Backlog' ||
      (projectType === 'Planning' && task.isInSprint())
    ) {
      noDisplayFields.push(
        'Status',
        'WorkRemaining',
        'SprintPriority',
        'ResourceAllocationFirst',
        'LinkedTo',
      );
    } else if (projectType === 'Planning' && !task.isInSprint()) {
      noDisplayFields.push('Status', 'LinkedTo');
    }

    if (noDisplayFields.includes(field.id)) {
      return null;
    }

    if (
      field.Type === 'Workflow' ||
      field.id === 'Duration' ||
      field.id === 'BoardCommit_ColumnID' ||
      field.id === 'BoardCommit_LaneID'
    ) {
      return <ReadOnlyField key={field.id} task={task} $FieldID={field.id} />;
    }

    if (field.Type === 'String') {
      return <StringField key={field.id} task={task} $FieldID={field.id} />;
    } else if (field.Type === 'Hyperlink') {
      return <HyperlinkField key={field.id} task={task} $FieldID={field.id} />;
    } else if (field.Type === 'Enum' || field.Type === 'InlineEnum') {
      if (field.id === 'Status') {
        if (task.hasWorkflow()) {
          return (
            <WorkflowStatusFieldContainer
              key={field.id}
              task={task}
              $FieldID={field.id}
            />
          );
        } else {
          return <StatusFieldContainer key={field.id} task={task} />;
        }
      } else if (field.id === 'SprintPriority' || field.id === 'BugPriority') {
        return (
          <PriorityFieldContainer
            key={field.id}
            task={task}
            $FieldID={field.id}
          />
        );
      } else {
        return (
          <EnumFieldContainer key={field.id} task={task} $FieldID={field.id} />
        );
      }
    } else if (field.Type === 'Hours' && field.id.indexOf('CC_') === 0) {
      return <TimeSpent key={field.id} task={task} $FieldID={field.id} />;
    } else if (
      field.Type === 'Integer' ||
      field.Type === 'Float' ||
      field.Type === 'Hours'
    ) {
      return <NumberField key={field.id} task={task} $FieldID={field.id} />;
    } else if (field.Type === 'DateTime' || field.Type === 'DateTimeTime') {
      return <DateTimeField key={field.id} task={task} $FieldID={field.id} />;
    } else if (field.Type === 'MultiEnum' || field.Type === 'InlineMultiEnum') {
      return <MultiEnumField key={field.id} task={task} $FieldID={field.id} />;
    } else if (field.Type === 'Resources') {
      return (
        <ResourcesField
          key={field.id}
          task={task}
          $FieldID={field.id}
          groups={this.props.groups}
          resources={this.props.resources}
        />
      );
    } else if (field.Type === 'ResourceAllocations') {
      return (
        <AllocationsField
          key={field.id}
          task={task}
          $FieldID={field.id}
          workflowStatus={task.getProperty('WorkflowStatus')}
        />
      );
    } else if (field.Type === 'MilestoneMultiEnum') {
      return (
        <MilestoneFieldContainer
          key={field.id}
          task={task}
          $FieldID={field.id}
          milestones={this.props.milestones}
        />
      );
    } else if (
      projectType === 'QA' &&
      field.Type === 'BugPriority' &&
      this.props.task.isInSprint()
    ) {
      return (
        <PriorityFieldContainer
          key={field.id}
          task={task}
          $FieldID={'BugPriority'}
        />
      );
    } else {
      console.log('Unknown field: ' + field.Type + ' ' + field.id);
      return null;
    }
  }

  sortByFieldName(first, second) {
    if (first.DisplayName.toUpperCase() < second.DisplayName.toUpperCase())
      return -1;
    if (first.DisplayName.toUpperCase() > second.DisplayName.toUpperCase())
      return 1;
  }

  buildFieldNameArray() {
    let fieldDefArray = [];

    const projectData = getProjectInfo(this.props.task.$ProjectID);
    const CommittedToProjectID = parseInt(
      this.props.task.fields.CommittedToProjectID,
    );

    let fieldNameArray = Object.keys(this.props.task.fields);
    fieldNameArray.forEach((item) => {
      const fieldDef = this.props.singlelinefields.find(
        function (field) {
          return (
            field.id === this.fieldname &&
            (field.projectID === projectData[0].id ||
              field.projectID === CommittedToProjectID)
          );
        },

        { fieldname: item },
      );
      if (isValidObject(fieldDef)) {
        fieldDefArray.push(fieldDef);
      }
    });

    return fieldDefArray;
  }

  render() {
    const { singlelinefields } = this.props;
    const currentItem = this.props.task;
    const projectData = getProjectInfo(currentItem.$ProjectID);
    const projectType = projectData[0].Type;

    const fieldDefs = this.buildFieldNameArray();
    const assignedField = filterCoreFieldsByProject(
      parseInt(this.props.task.$ProjectID, 10),
    ).find(function (field) {
      return field.id === 'ResourceAllocationFirst';
    });

    let prioritisedFields = null;
    if (projectType === 'QA') {
      prioritisedFields = (
        <div className="importantfields">
          {currentItem.hasWorkflow() ? (
            <WorkflowStatusFieldContainer
              task={currentItem}
              $FieldID={'Status'}
            />
          ) : (
            <StatusFieldContainer task={currentItem} />
          )}
          <EnumFieldContainer task={currentItem} $FieldID={'Severity'} />
          {this.props.task.isInSprint() ? (
            <PriorityFieldContainer
              task={currentItem}
              $FieldID={'SprintPriority'}
            />
          ) : (
            <PriorityFieldContainer
              task={currentItem}
              $FieldID={'BugPriority'}
            />
          )}
          {isValidObject(assignedField) ? (
            <AllocationsField
              task={currentItem}
              $FieldID={'ResourceAllocationFirst'}
              workflowStatus={currentItem.getProperty('WorkflowStatus')}
            />
          ) : null}
        </div>
      );
    } else if (this.props.task.isInSprint()) {
      prioritisedFields = (
        <div className="importantfields">
          {currentItem.hasWorkflow() ? (
            <WorkflowStatusFieldContainer
              task={currentItem}
              $FieldID={'Status'}
            />
          ) : (
            <StatusFieldContainer task={currentItem} />
          )}
          <PriorityFieldContainer
            task={currentItem}
            $FieldID={'SprintPriority'}
          />
          <NumberField task={currentItem} $FieldID={'WorkRemaining'} />
          {isValidObject(assignedField) ? (
            <AllocationsField
              task={currentItem}
              $FieldID={'ResourceAllocationFirst'}
              workflowStatus={currentItem.getProperty('WorkflowStatus')}
            />
          ) : null}
        </div>
      );
    } else if (
      !this.props.task.isInSprint() &&
      this.props.task.fields.CommittedToProjectID !== undefined
    ) {
      prioritisedFields = (
        <div className="importantfields">
          {currentItem.hasWorkflow() ? (
            <WorkflowStatusFieldContainer
              task={currentItem}
              $FieldID={'Status'}
            />
          ) : (
            <StatusFieldContainer task={currentItem} />
          )}
          {isValidObject(assignedField) ? (
            <AllocationsField
              task={currentItem}
              $FieldID={'ResourceAllocationFirst'}
              workflowStatus={currentItem.getProperty('WorkflowStatus')}
            />
          ) : null}
          <TimeZoneField
            task={currentItem}
            $FieldID={'TimeZoneStart'}
            isEdit={true}
          />
          <TimeZoneField
            task={currentItem}
            $FieldID={'TimeZoneEnd'}
            isEdit={true}
          />
        </div>
      );
    } else {
      prioritisedFields = (
        <div className="importantfields">
          {currentItem.hasWorkflow() ? (
            <WorkflowStatusFieldContainer
              task={currentItem}
              $FieldID={'Status'}
            />
          ) : (
            <StatusFieldContainer task={currentItem} />
          )}
          <PriorityFieldContainer
            task={currentItem}
            $FieldID={'BacklogPriority'}
          />
        </div>
      );
    }

    return (
      <div style={{ width: '100%', paddingRight: '10px' }}>
        {prioritisedFields}
        {fieldDefs
          .sort(this.sortByFieldName)
          .map((field) => this.renderField(field.id, singlelinefields))}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  todos: state.todos,
  singlelinefields: state.singlelinefields,
  projects: state.projects,
  resources: state.resources,
  groups: state.groups,
});

export default connect(mapStateToProps)(SinglelineLayout);
