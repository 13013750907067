import { chartResultSetsConstants } from '../constants/chartresultsets.constants';
import { CLEAR_CACHE } from '../constants/general.constants';

const chartResultSets = (state = [], action) => {
  switch (action.type) {
    case chartResultSetsConstants.ADD_RESULTSET: {
      const resultSetId = parseInt(action.id);
      const resultSetIndex = state.findIndex(
        (resultset) => resultset.id === resultSetId,
      );
      if (resultSetIndex >= 0) {
        return state.map((resultset) => {
          if (resultset.id === resultSetId) {
            return {
              id: resultSetId,
              ...action.fields,
            };
          }

          return resultset;
        });
      } else {
        return [
          ...state,
          {
            id: parseInt(action.id),
            ...action.fields,
          },
        ];
      }
    }
    case chartResultSetsConstants.REMOVE_RESULTSET: {
      const removedResultSetId = parseInt(action.id);
      const resultSetIndex = state.findIndex(
        (resultset) => resultset.id === removedResultSetId,
      );
      if (resultSetIndex >= 0) {
        return [
          ...state.slice(0, resultSetIndex),
          ...state.slice(resultSetIndex + 1),
        ];
      } else {
        return state;
      }
    }
    case CLEAR_CACHE: {
      return [];
    }
    default: {
      return state;
    }
  }
};

export default chartResultSets;
