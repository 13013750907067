import React, { Component, KeyboardEvent, ReactNode } from 'react';
import { connect } from 'react-redux';

import { filterConstants } from '../../../constants/filter.constants';

interface IProps {
  filteroptions: any;
  dispatch(message: any): void;
}

export class SearchField extends Component<IProps> {
  state = {
    searchValue: this.props.filteroptions.stringfilter,
  };

  componentDidUpdate(prevProps: IProps): void {
    if (
      prevProps.filteroptions.stringfilter !==
      this.props.filteroptions.stringfilter
    ) {
      this.setState({ searchValue: this.props.filteroptions.stringfilter });
    }
  }

  // TODO: Look into why we use that lifecycle method
  UNSAFE_componentWillReceiveProps(nextProps: IProps): void {
    let stringfilter = nextProps.filteroptions.stringfilter;
    this.setState({ searchValue: stringfilter });
  }

  onChange = (value: string): void => {
    this.setState({ searchValue: value });
  };

  clickSearchButton(): void {
    const message = {
      type: filterConstants.STRING_FILTER,
      stringfilter: this.state.searchValue,
    };
    const { dispatch } = this.props;
    dispatch(message);
  }

  handleKeyPress = (event: KeyboardEvent): void => {
    if (event.key === 'Enter') {
      this.clickSearchButton();
    }
  };

  render(): ReactNode {
    return (
      <input
        className="search-input"
        placeholder="Search..."
        onChange={(event) => {
          this.onChange(event.target.value);
        }}
        onKeyPress={this.handleKeyPress}
        value={this.state.searchValue}
      />
    );
  }
}

const mapStateToProps = (state: any) => ({
  filteroptions: state.filteroptions,
});

export default connect(mapStateToProps)(SearchField);
