import { CLEAR_CACHE, SERVICE_VERSION } from '../constants/general.constants';

const serviceVersion = (state = {}, action) => {
  switch (action.type) {
    case SERVICE_VERSION:
      return { ...state, serviceVersion: action.serviceVersion };
    case CLEAR_CACHE:
      return {};
    default:
      return state;
  }
};

export default serviceVersion;
