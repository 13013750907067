import React from 'react';
import { Segment } from 'semantic-ui-react';
import Datetime from 'react-datetime';
import moment from 'moment';
import 'react-datetime/css/react-datetime.css';

import BaseField from './BaseField';
import FieldStyle from './FieldStyle';
import { setField } from '../../DDPJS/DDPJS';
import {
  createEJSONDateObject,
  isValidEJSONDateObject,
  getEJSONDateValue,
} from '../../EJSON/EJSON_Date';
import {
  formatYYYYMMDD,
  formatTime24Hour,
  getLocaleDateString,
} from '../../helpers/date_time_format';
import ItemDetailsField from './components/ItemDetailsField';
import { isValidObject } from '../../helpers/validation_functions';

export default class DateTimeField extends BaseField {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.convertValueToTimestamp = this.convertValueToTimestamp.bind(this);
  }

  state = {
    value: getEJSONDateValue(this.props.task.fields, this.props.$FieldID),
    redraw: false,
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.value !== this.state.value) {
      return;
    } else if (
      isValidEJSONDateObject(this.getValue()) &&
      prevState.value !==
        getEJSONDateValue(this.props.task.fields, this.props.$FieldID)
    ) {
      this.setState({
        value: getEJSONDateValue(this.props.task.fields, this.props.$FieldID),
      });
    } else if (
      prevState.value > 0 &&
      !isValidEJSONDateObject(this.getValue())
    ) {
      this.setState({
        value: 0,
      });
    }
  }

  convertValueToTimestamp(moment) {
    if (this.timeFormat()) return moment.utc().toDate().getTime();

    const dateValue = moment.toDate();
    return Date.UTC(
      dateValue.getFullYear(),
      dateValue.getMonth(),
      dateValue.getDate(),
    );
  }

  onChange = (moment) => {
    try {
      if (this.readOnlyFlag()) {
        return;
      }

      if (
        !isValidObject(moment) ||
        typeof moment === 'string' ||
        !moment.isValid()
      ) {
        return;
      }

      this.setState({ value: this.convertValueToTimestamp(moment) });
    } catch (error) {
      console.error(error);
    }
  };

  onBlur = (moment) => {
    if (this.readOnlyFlag()) {
      return;
    }

    if (typeof moment === 'string' && moment.trim() === '') {
      setField(this.getFieldDefinition().id, this.getItemID(), moment);
      this.setState({ value: 0 });
      this.setState({ redraw: true });
      return;
    }

    if (typeof moment === 'string' || !moment.isValid()) {
      this.setState({ value: 0 });
      this.setState({ redraw: true });
      return;
    }

    const dateValue = this.convertValueToTimestamp(moment);
    this.setState({ value: dateValue });
    setField(
      this.getFieldDefinition().id,
      this.getItemID(),
      createEJSONDateObject(dateValue),
    );
  };

  timeFormat() {
    return this.getFieldDefinition().Type === 'DateTimeTime';
  }

  render() {
    if (!isValidObject(this.getFieldDefinition())) {
      return null;
    }

    const dateValue = new Date(this.state.value);
    const myMoment = moment(dateValue);
    const isValid = myMoment.isValid() && this.state.value > 0;
    if (!isValid && this.state.value === 0 && this.state.redraw) {
      this.setState({ redraw: false });
    }

    if (this.readOnlyFlag()) {
      return (
        <ItemDetailsField
          fieldName={this.getFieldDefinition().DisplayName}
          isRequiredField={this.isWorkflowRequiredField()}
        >
          <div style={FieldStyle.ReadOnlyBackgroundStyle}>
            <span
              style={{
                ...ReadOnlyDateStyle,
                ...FieldStyle.ReadOnlyFieldStyle,
              }}
            >
              {isValid
                ? `${formatYYYYMMDD(dateValue)} ${
                    this.timeFormat() ? ' ' + formatTime24Hour(dateValue) : ''
                  }`
                : ''}
            </span>
          </div>
        </ItemDetailsField>
      );
    } else {
      return (
        <ItemDetailsField fieldName={this.getFieldDefinition().DisplayName}>
          <div>
            <Segment style={FieldStyle.FieldSegment}>
              {isValid ? (
                <Datetime
                  timeFormat={this.timeFormat()}
                  dateFormat={getLocaleDateString()}
                  locale={navigator.language}
                  closeOnSelect={true}
                  value={dateValue}
                  onChange={this.onChange}
                  onBlur={this.onBlur}
                />
              ) : (
                !this.state.redraw && (
                  <Datetime
                    timeFormat={this.timeFormat()}
                    dateFormat={getLocaleDateString()}
                    locale={navigator.language}
                    closeOnSelect={true}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                  />
                )
              )}
            </Segment>
          </div>
        </ItemDetailsField>
      );
    }
  }
}

const ReadOnlyDateStyle = {
  fontFamily: 'Roboto',
  color: '#FFFFFF',
  fontSize: '1rem',
};
