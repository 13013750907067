import { ddpConstants } from '../constants/DDP.constants';
import { CLEAR_CACHE } from '../constants/general.constants';

const ids = (state = {}, action) => {
  switch (action.type) {
    case ddpConstants.LOGINID:
      return { ...state, loginid: action.loginid };
    case ddpConstants.RENEWID:
      return { ...state, renewid: action.renewid };
    case CLEAR_CACHE:
      return {};
    default:
      return state;
  }
};

export default ids;
