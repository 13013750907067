import React from 'react';

import RelationList from '../../ItemDetail/components/RelationList';
import { ImgAttachment } from '../../../images/images';
import { mapFileIDToInfo } from '../../../services/versioncontrol';

export default class Attachments extends React.Component {
  render() {
    if (
      !this.props.attachedDocuments ||
      this.props.attachedDocuments.length === 0
    )
      return null;

    let items = [];
    for (let attachment of this.props.attachedDocuments) {
      const urlParts = attachment.FilePath.split('/');

      const fileInfo = mapFileIDToInfo(this.props.task, attachment.FileID);
      const fileName = urlParts[urlParts.length - 1];

      if (fileInfo && fileName.indexOf('$THUMBNAIL$') === -1) {
        items.push({
          text: fileName,
          link: fileInfo.url,
          download: true,
        });
      }
    }

    return <RelationList icon={ImgAttachment} items={items} />;
  }
}
