import { commentConstants } from '../constants/comment.constants';
import { CLEAR_CACHE } from '../constants/general.constants';
import assignIn from 'lodash/merge';

function buildObject(comment, id, fields) {
  let newObject = {
    ...comment,
    id: id,
  };
  assignIn(newObject, fields);

  return newObject;
}

function checkForCommentPreExist(state, id) {
  return (
    state.findIndex(function (comment) {
      return comment.id === id;
    }) !== -1
  );
}

const comments = (state = [], action) => {
  switch (action.type) {
    case commentConstants.ADD_COMMENT:
      // TODO: find a solution for this rule
      // eslint-disable-next-line no-case-declarations
      let mycomment = {};
      if (checkForCommentPreExist(state, action.id)) {
        return state;
      } else {
        return [...state, buildObject(mycomment, action.id, action.fields)];
      }
    case commentConstants.UPDATE_COMMENT:
      return state.map((comment) => {
        return comment.id === action.id
          ? buildObject(comment, action.id, action.fields)
          : comment;
      });
    case commentConstants.REMOVE_COMMENT:
      // TODO: find a solution for this rule
      // eslint-disable-next-line no-case-declarations
      const myindex = state.findIndex((comment) => comment.id === action.id);
      if (myindex >= 0) {
        return [...state.slice(0, myindex), ...state.slice(myindex + 1)];
      } else {
        return state;
      }
    case commentConstants.RESET:
    case CLEAR_CACHE:
      state.length = 0;
      return state; // MJT_COMMENT future Mike, make sure the memory actually gets freed by losing reference to objects
    default:
      return state;
  }
};

export default comments;
