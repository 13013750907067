export enum MyWorkIgnoredFields {
  LOCKED_TYPE = 'LockedType',
  TYPE = 'Type',
  ATTACHED_DOCUMENTS = 'AttachedDocuments',
  TIME_ZONES = 'TimeZones',
  COMMENTS_OPTIONS = 'CommentsOptions',
  USER_STORY_FLAG = 'UserStoryFlag',
  EPIC_FLAG = 'EpicFlag',
  LINKED_TO = 'LinkedTo',
  ARCHIVED = 'Archived',
  LINKED_TO_SPRINT = 'LinkedToSprint',
}
