import React from 'react';

import './tasklist.scss';
import Task from './task';

class TaskList extends React.Component {
  render() {
    return (
      <div className="tasklist">
        <table>
          <thead>
            <tr>
              {this.props.enabledFields.map((enabledField) => {
                let className = '';
                if (enabledField.id === 'Description') {
                  className = 'is-wide';
                  enabledField.name = 'Description';
                } else if (enabledField.id === 'WorkRemaining')
                  className = 'is-narrow';

                className += ` ${enabledField.id}`;

                return (
                  <th key={enabledField.id} className={className}>
                    {enabledField.name || enabledField.id}
                  </th>
                );
              })}
            </tr>
          </thead>
          {this.props.taskGroups.map((taskGroup, index) => {
            if (taskGroup.tasks.length === 0) return null;

            return (
              <tbody key={index}>
                <tr>
                  <td
                    className="group is-disabled"
                    colSpan={this.props.enabledFields.length}
                  >
                    {taskGroup.name}
                  </td>
                </tr>
                {taskGroup.tasks.map((task) => (
                  <Task key={task.$ID} task={task} {...this.props} />
                ))}
              </tbody>
            );
          })}
        </table>
      </div>
    );
  }
}

export default TaskList;
