import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import { DebounceInput } from 'react-debounce-input';

import { ImgCheckMark } from '../../../images/images';
import { fetchMoreItems } from '../../../DDPJS/DDPJS';

export default class FloatingSelection extends React.Component {
  constructor(props) {
    super(props);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleSelectionChanged = this.handleSelectionChanged.bind(this);

    let selectedValues = [];
    this.props.options.forEach((item) => {
      if (item.selected) selectedValues.push(item.value);
    });

    this.state = {
      selectedValues: selectedValues,
      changedMultiSelection: false,
      findText: '',
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);

    if (this.props.findData) this.applyNewFindTextChange(this.state.findText);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target))
      this.props.onClose();
  }

  isExclusive(value) {
    for (const option of this.props.options) {
      if (option.value === value && option.exclusive) return true;
    }

    return false;
  }

  isMatchFindText(itemText) {
    if (this.props.findData && this.state.findText === '') return true;

    return (
      String(itemText)
        .toLowerCase()
        .indexOf(String(this.state.findText).toLowerCase()) !== -1
    );
  }

  applyNewFindTextChange(newFindText) {
    this.setState({
      findText: newFindText,
    });

    this.props.findData.onFindTextChanged(newFindText);
  }

  handleSelectionChanged(value) {
    if (this.props.multiSelection) {
      let selectedValues = this.state.selectedValues;
      const isExclusive = this.isExclusive(value);
      if (isExclusive) {
        selectedValues = [value];
      } else {
        let unselectExisting = false;
        for (let selectedValue of selectedValues) {
          if (this.isExclusive(selectedValue)) {
            unselectExisting = true;
            break;
          }
        }

        if (unselectExisting) {
          selectedValues = [value];
        } else {
          const existingIndex = selectedValues.indexOf(value);
          if (existingIndex === -1) selectedValues.push(value);
          else selectedValues.splice(existingIndex, 1);
        }
      }

      this.setState({
        selectedValues,
        changedMultiSelection: true,
      });
    } else {
      this.props.onSelectionChanged(value);
    }
  }

  render() {
    let maxWidth = 320;
    let minWidth = 0;
    if (this.props.fitToElement)
      minWidth = Math.min(
        this.props.fitToElement.getBoundingClientRect().width,
        maxWidth,
      );

    return (
      <div
        className="floatingselection js-floatingselection"
        ref={(element) => (this.wrapperRef = element)}
        onScroll={(event) => {
          if (!this.props.subscriptionId) return; // Not able to fetch more results

          let element = event.target;
          if (element.scrollHeight - element.scrollTop === element.clientHeight)
            fetchMoreItems(this.props.subscriptionId, 5);
        }}
      >
        <div className="floatingselection-list .js-floatingselection-list">
          {this.props.options.map((option) => {
            const isSelected =
              this.state.selectedValues.indexOf(option.value) !== -1;

            if (!this.isMatchFindText(option.text, isSelected)) {
              return null;
            }

            let classes = [
              'floatingselection-item',
              'js-floatingselection-item',
            ];
            if (isSelected && !this.props.multiSelection)
              classes.push('is-selected');

            if (option.seperator) {
              return (
                <div key="seperator" className="floatingselection-seperator" />
              );
            }

            let itemNameClasses = ['floatingselection-item-name'];
            if (option.value === 'invalid') itemNameClasses.push('can-wrap');

            return (
              <div
                key={option.value}
                className={classes.join(' ')}
                onClick={() => this.handleSelectionChanged(option.value)}
                style={{ maxWidth: maxWidth + 'px', minWidth: minWidth + 'px' }}
              >
                <div
                  style={{
                    display: 'flex',
                  }}
                >
                  <div className={itemNameClasses.join(' ')}>
                    {option.image && (
                      <div className="js-floatingselection-item-name">
                        <img
                          className="floatingselection-item-icon"
                          src={option.image}
                          alt={''}
                          onError={(event) => {
                            if (option.fallbackImage)
                              event.target.src = option.fallbackImage;
                          }}
                        />
                      </div>
                    )}
                    <div className="js-floatingselection-item-name">
                      {option.text}
                    </div>
                  </div>
                  {this.props.multiSelection && (
                    <div className="floatingselection-item-check">
                      {isSelected && <img src={ImgCheckMark} alt="" />}
                    </div>
                  )}
                </div>
                {option.subText && (
                  <div className="floatingselection-item-subtext">
                    {option.subText}
                  </div>
                )}
              </div>
            );
          })}
        </div>
        <div className="floatingselection-bottomarea">
          {this.props.findData ? (
            <DebounceInput
              className="floatingselection-findfield js-floatingselection-findfield"
              minLength={2}
              debounceTimeout={300}
              onChange={(event) =>
                this.applyNewFindTextChange(event.target.value)
              }
              value={this.state.findText}
            />
          ) : null}
          {this.props.multiSelection && (
            <div className="floatingselection-buttonbox js-floatingselection-buttonbox">
              <Button
                onClick={() =>
                  this.props.onSelectionChanged(
                    this.state.selectedValues,
                    this.state.changedMultiSelection,
                  )
                }
              >
                Ok
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

FloatingSelection.propTypes = {
  subscriptionId: PropTypes.string,
  fitToElement: PropTypes.any,
  onSelectionChanged: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  multiSelection: PropTypes.bool,
  findData: PropTypes.shape({
    onFindTextChanged: PropTypes.func,
  }),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      fallbackImage: PropTypes.string,
      seperator: PropTypes.bool,
      text: PropTypes.string,
      subText: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      exclusive: PropTypes.bool,
    }),
  ),
};
