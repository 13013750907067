import React from 'react';
import { connect } from 'react-redux';

import { history } from '../../helpers/history';

class BackLinkControl extends React.Component {
  constructor(props) {
    super(props);
    this.clickBackToList = this.clickBackToList.bind(this);
  }

  clickBackToList(event) {
    event.preventDefault();
    if (this.props.shared.integration.enabled) {
      history.push(this.props.shared.integration.data.redirectPath);
    } else {
      history.push('/');
    }
  }

  render() {
    return (
      // eslint-disable-next-line
      <span>
        <a
          href="javascript;"
          onClick={this.clickBackToList}
          className="ItemDetail-ButtonLink"
        >
          &lt; Back to To-Do list
        </a>
      </span>
    );
  }
}

export default connect()(BackLinkControl);
