import React from 'react';

import BuiltInSingleSelect from '../components/BuiltInSingleSelect';
import {
  ImgNotDone,
  ImgInProgress,
  ImgCompleted,
  ImgBlocked,
  ImgToBeDeleted,
} from '../../../images/images';

const statusIcons = [
  ImgNotDone,
  ImgInProgress,
  ImgCompleted,
  ImgBlocked,
  ImgToBeDeleted,
];

export default class StatusFieldContainer extends React.Component {
  getIcon(value) {
    if (value >= 2 && value <= statusIcons.length + 2)
      return statusIcons[value - 2];
    else return null;
  }

  getText(field) {
    const fieldEnum = field.getFieldDefinition().Enum;
    const numItems = fieldEnum ? fieldEnum.length : 0;

    for (let index = 0; index < numItems; ++index) {
      if (fieldEnum[index][0] === field.state.value) return fieldEnum[index][1];
    }

    return '';
  }

  render() {
    return (
      <BuiltInSingleSelect
        task={this.props.task}
        $FieldID={'Status'}
        getOptions={(field) => {
          const fieldEnum = field.getFieldDefinition().Enum;
          const numItems = fieldEnum ? fieldEnum.length : 0;

          let options = [];

          for (let index = 0; index < numItems; ++index) {
            if (fieldEnum[index][0] === 1) continue;

            const value = fieldEnum[index][0];

            options.push({
              value: value,
              selected: value === field.state.value,
              image: this.getIcon(fieldEnum[index][0]),
              text: fieldEnum[index][1],
            });
          }

          return options;
        }}
        getIcon={(field) => {
          return this.getIcon(field.state.value);
        }}
        getText={(field) => {
          return this.getText(field);
        }}
        isListView={this.props.isListView}
      />
    );
  }
}
