export function processUpdateColumnMetaData(
  state,
  action,
  projectID,
  columnBuilderCallback,
) {
  const myIndex = state.findIndex(
    (field) => field.id === action.id && field.projectID === projectID,
  );
  if (myIndex >= 0) {
    if (action.fields && action.fields.NewID) {
      let existingColumn = state[myIndex];
      existingColumn.id = action.fields.NewID;

      return [
        ...state.slice(0, myIndex),
        existingColumn,
        ...state.slice(myIndex + 1),
      ];
    } else {
      return [
        ...state.slice(0, myIndex),
        columnBuilderCallback(action),
        ...state.slice(myIndex + 1),
      ];
    }
  }
  return state;
}
