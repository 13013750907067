import { errormessageConstants } from '../constants/errormessage.constants';
import {
  MessageEnum,
  MsgLevelEnum,
} from '../components/MessageDialog/MessageIndex';

const errormessage = (
  state = {
    iserror: false,
    messageID: MessageEnum.NO_ERROR,
    msgLevel: MsgLevelEnum.INFORMATION,
    customMessage: '',
  },
  action,
) => {
  switch (action.type) {
    case errormessageConstants.SET_ERROR_MESSAGE:
      return {
        ...state,
        iserror: true,
        messageID: action.messageID,
        msgLevel: action.msgLevel,
        type: action.type,
      };
    case errormessageConstants.SET_CUSTOM_MESSAGE:
    case errormessageConstants.SET_INTEGRATION_MESSAGE:
      return {
        ...state,
        iserror: true,
        messageID: action.messageID,
        msgLevel: action.msgLevel,
        customMessage: action.customMessage,
        type: action.type,
      };
    case errormessageConstants.CLEAR_ERROR_MESSAGE:
      return {
        ...state,
        iserror: false,
        messageID: MessageEnum.NO_ERROR,
        msgLevel: MsgLevelEnum.INFORMATION,
        customMessage: '',
        type: action.type,
      };
    default:
      return state;
  }
};

export default errormessage;
