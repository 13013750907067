import { stringConstants } from '../../../constants/strings.constants';
import styles from './Copyright.module.scss';
import React from 'react';

export const Copyright = (props) => {
  const { serviceVersion } = props;

  return (
    <div className={styles.Copyright}>
      <ul className={styles.List}>
        <li>{stringConstants.COPYRIGHT}</li>
        <li>
          <a
            href={stringConstants.PRODUCT_URL}
            target="_blank noopener noreferrer"
            className={styles.Link}
          >
            {stringConstants.PRODUCT_URL_DISPLAY}
          </a>
        </li>
        <li>
          <a
            href={`mailto:${stringConstants.SUPPORT_EMAIL}`}
            className={styles.Link}
          >
            {stringConstants.CONTACT_SUPPORT}
          </a>
        </li>
        <li>{`${stringConstants.WEB_SERVICE_VERSION} ${
          serviceVersion ?? ''
        }`}</li>
      </ul>
    </div>
  );
};

export default Copyright;
