import React from 'react';
import { connect } from 'react-redux';

import ToDoList from '../ToDoList';
import TitleBar from '../../TitleBar/TitleBar';
import MessageDialog from '../../MessageDialog/MessageDialog';
import { triggerMessageDialog } from '../../MessageDialog/MessageUtilities';
import { collapseConstants } from '../../../constants/collapse.constants';
import IntegrationHeader from '../../../web_integrations/integrationHeader/integrationHeader.js';

import './Layout.scss';

class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.onToggleFilter = this.onToggleFilter.bind(this);

    // This is enter point for show specific view
    // and here we decided whether this is going be normal view
    // or extended integration view
    this.props.shared.onToogleIntegration(this.props.integrationData);
  }

  onToggleFilter() {
    const { dispatch } = this.props;
    dispatch({ type: collapseConstants.TOGGLE });
  }

  componentDidUpdate() {
    if (this.props.errormessage.iserror)
      triggerMessageDialog(this.props.errormessage.iserror);

    if (
      this.props.integrationData &&
      this.props.integrationData.cleanupIntegrationData
    ) {
      this.props.integrationData.cleanupIntegrationData = false;
      this.props.shared.onToogleIntegration(null);
    }
  }

  componentDidMount() {
    triggerMessageDialog(this.props.errormessage.iserror);
  }

  render() {
    return (
      <div>
        <TitleBar
          {...this.props}
          currentRoute="todo"
          callbackFromParent={this.toggleVisibility}
          onToggleFilter={this.onToggleFilter}
          onToggleTheme={this.props.shared.onToggleTheme}
        />
        {this.props.shared.integration.enabled ? (
          <div className="integrationBlock">
            <IntegrationHeader {...this.props} />
          </div>
        ) : null}
        <div>
          <MessageDialog />
          <ToDoList {...this.props} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentuser: state.currentuser,
  collapseState: state.collapseState,
  errormessage: state.errormessage,
  appState: state.appState,
});

export default connect(mapStateToProps)(Layout);
